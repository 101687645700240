import { Routes } from '@angular/router';
import { YardTurnaroundTimeViolationComponent } from './yard-turnaround-time-violation/yard-turnaround-time-violation.component';

export const YardTurnaroundTimeViolationRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: YardTurnaroundTimeViolationComponent,
      },
    ],
  },
];
