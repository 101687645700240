import { Routes } from '@angular/router';

const modulesAngular = '@ov-suite/modules-angular';

export const mobileRoutes: Routes = [
  {
    path: 'form-builder',
    loadChildren: () => import(modulesAngular).then(m => m.FormBuilderModule),
  },
  {
    path: 'mobile-list-config',
    loadChildren: () => import(modulesAngular).then(m => m.MobileListConfigModule),
  },
  {
    path: 'mobile-start-config',
    loadChildren: () => import(modulesAngular).then(m => m.MobileStartConfigModule),
  },
  {
    path: 'mobile-sod',
    loadChildren: () => import(modulesAngular).then(m => m.MobileSodModule),
  },
  {
    path: 'mobile-eod',
    loadChildren: () => import(modulesAngular).then(m => m.MobileEodModule),
  },
]
