import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { EntryReason } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: EntryReason,
      api: 'yardlink',
      createTitle: 'Entry Reason',
      route: ['entry-reason'],
      tableTitle: 'Entry Reasons',
    }),
  ],
})
export class EntryReasonModule {}
