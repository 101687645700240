import { DashboardWidget, OvSuiteChart } from './charts.constants';

export interface DateRange {
  fromDate: Date;
  toDate: Date;
}

export const EntriesWidget = new DashboardWidget('count', '', 'Avg Number Of Entries', 'fa fa-truck');

export const YardTurnAroundWidget = new DashboardWidget('avgYardTurnaround', '', 'Avg Yard Turn Around', 'fa fa-clock-o');

export const TotalTurnAroundWidget = new DashboardWidget('avgTotalTurnaround', '', 'Avg Total Turn Around', 'fa fa-flag-checkered');

export const FenceTurnAroundWidget = new DashboardWidget('fenceTurnAround', '', 'Avg Fence Turn Around', 'fa fa-map-marker');

export const TotalArrivals = new OvSuiteChart(
  'Vehicle Arrivals',
  {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'count',
        type: 'bar',
        barThickness: 15,
        maxBarThickness: 50,
        backgroundColor: '#A9E5FF',
        borderColor: '#A9E5FF',
        hoverBackgroundColor: '#80D4F8',
      },
    ],
  },
  {
    responsive: true,
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        title: {
          display: true,
          text: '# Vehicles',
          padding: 6,
        },
        ticks: {
          stepSize: 3,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  'line',
  'getVehicleEntries',
  [EntriesWidget],
);

export const TotalTurnAroundTimes = new OvSuiteChart(
  'Vehicle Turn Around',
  {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'avgYardTurnaround',
        type: 'bar',
        backgroundColor: '#A9E5FF',
        borderColor: '#A9E5FF',
        hoverBackgroundColor: '#80D4F8',
      },
      {
        data: [],
        label: 'avgTotalTurnaround',
        type: 'bar',
        backgroundColor: '#9EAFFF',
        borderColor: '#9EAFFF',
        hoverBackgroundColor: '#6791FF',
      },
    ],
  },
  {
    responsive: true,
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Time (Hours)',
          padding: 6,
        },
        ticks: {
          stepSize: 3,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  'line',
  'getTotalAverageTurnaroundTimes',
  [YardTurnAroundWidget, TotalTurnAroundWidget],
);

export const InYardActivity = new OvSuiteChart(
  'In Yard Activity',
  {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'arrivals',
        type: 'bar',
        backgroundColor: '#A9E5FF',
        borderColor: '#A9E5FF',
        hoverBackgroundColor: '#80D4F8',
      },
      {
        data: [],
        label: 'entries',
        type: 'bar',
        backgroundColor: '#9EAFFF',
        borderColor: '#9EAFFF',
        hoverBackgroundColor: '#6791FF',
      },
      {
        data: [],
        label: 'exits',
        type: 'bar',
        backgroundColor: '#ffc3a9',
        borderColor: '#ffc3a9',
        hoverBackgroundColor: '#f89d75',
      },
      {
        type: 'line',
        data: [],
        label: 'inYard',
        backgroundColor: '#F4FCFF',
        pointBackgroundColor: '#A9E5FF',
        pointBorderColor: '#A9E5FF',
        pointRadius: 3,
        order: 2,
        fill: true,
        borderColor: '#A9E5FF',
        tension: 0.5,
      },
    ],
  },
  {
    responsive: true,
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        title: {
          display: true,
          text: '# Vehicles',
          padding: 6,
        },
        ticks: {
          stepSize: 3,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  'line',
  'getInYardActivity',
);

export const FenceTurnAround = new OvSuiteChart(
  'Fence Turn Around Times',
  {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'fenceTurnAround',
        type: 'bar',
        backgroundColor: '#A9E5FF',
        borderColor: '#A9E5FF',
        barThickness: 25,
        maxBarThickness: 50,
        hoverBackgroundColor: '#80D4F8',
      },
    ],
  },
  {
    responsive: true,
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Time (Minutes)',
          padding: 6,
        },
        ticks: {
          stepSize: 5,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  'line',
  'getFenceTurnaround',
  [FenceTurnAroundWidget],
);

export const Exceptions = new OvSuiteChart(
  'Exceptions',
  {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'count',
        type: 'bar',
        backgroundColor: '#A9E5FF',
        borderColor: '#A9E5FF',
        barThickness: 25,
        maxBarThickness: 50,
        hoverBackgroundColor: '#80D4F8',
      },
    ],
  },
  // As number array
  // Change the name to suite
  {
    responsive: true,
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        title: {
          display: true,
          text: '# Exceptions',
          padding: 6,
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  },
  'line',
  'getExceptions',
);
