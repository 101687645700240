<div class="container-fluid">
  <mat-toolbar class="dashboard-toolbar">
    <h1 class="mat-headline">Statistics Dashboard</h1>
    <span class="spacer"></span>
    <ov-suite-dynamic-date-picker
      (dateRangeEmitter)="massUpdate($event)"
      [dateRange]="dateRange"
      class="global-date-picker"
    ></ov-suite-dynamic-date-picker>
  </mat-toolbar>

  <mat-grid-list cols="12" [rowHeight]="chartsRowHeight" (window:resize)="onResize($event)">
    <div *ngFor="let dashboard of dashboardCharts">
      <mat-grid-tile
        [colspan]="chartsColspan"
        [rowspan]="chartsRowspan"
        class="dashboard-chart-tile"
      >
        <ov-suite-charts
          [dashboardChart]="dashboard"
        >
        </ov-suite-charts>
      </mat-grid-tile>
    </div>
  </mat-grid-list>

  <mat-grid-list cols="12" [rowHeight]="widgetsRowHeight" (window:resize)="onResize($event)">
    <div *ngFor="let widget of dashboardWidgets">
      <mat-grid-tile
        [colspan]="widgetsColspan"
        [rowspan]="widgetsRowspan"
        class="dashboard-widget-title"
      >
        <ov-suite-widgets
          [widget]="widget"
        >
        </ov-suite-widgets>
      </mat-grid-tile>
    </div>
  </mat-grid-list>
</div>
