<ov-suite-table-top-tab-menu
  [tabs]="tabs"
  (tabClick)="onTabClick($event)">
</ov-suite-table-top-tab-menu>

<ov-suite-hierarchy-table
  *ngIf="tabs.length"
  [title]="tableTitle"
  [formClass]="formClass"
  [ovAutoService]="ovAutoService"
  [excludeColumns]="4"
  [hasBulkUpload]="false"
  [hideAddButton]="true"
  [showFiller]="false"
  [showTopBar]="true"
  [filterEnabled]="false"
  [showPageSelect]="false"
  [pageChangeEnabled]="false"
  [defaultOrderDirection]="'DESC'"
  [filter]="filter"
  [enableRefresh]="true"
>
</ov-suite-hierarchy-table>
