import { NgModule } from '@angular/core';
import { UiModule } from '@ov-suite/ui';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EnteredDepartedCollectionsListComponent } from './list/entered-departed-collections-list.component';
import { ExitEntryEventService } from '../../services/exit-entry-event/exit-entry-event.service';
import { EnteredDepartedCollectionsRoutes } from './entered-departed-collections.routing';

@NgModule({
  imports: [
  CommonModule,
  RouterModule.forChild(EnteredDepartedCollectionsRoutes),
  FormsModule,
  UiModule,
  ],
  declarations: [EnteredDepartedCollectionsListComponent],
  providers: [ExitEntryEventService],
})
export class EnteredDepartedCollectionsModule { }
