import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from '@ov-suite/ui';
import { FormsModule } from '@angular/forms';
import { StatisticsRoutes } from './statistics-dashboard.routing';
import { DashboardComponent } from './dashboard-v2/dashboard.component';
import { ChartsComponent } from '../../shared/components/charts/charts.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { NgChartsModule } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';

import { WidgetsComponent } from '../../shared/components/widgets/widgets.component';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [DashboardComponent, ChartsComponent, WidgetsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(StatisticsRoutes),
    UiModule,
    FormsModule,
    NgChartsModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatGridListModule,
    MatDividerModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
  ],
  providers: [],
  exports: [DashboardComponent, NgChartsModule],
})
export class StatisticsDashboardModule { }
