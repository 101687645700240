<div class="main-content">
  <div class="main-container">
    <ov-suite-hierarchy-table
      #hierarchyList
      [title]="'Site Violations'"
      [formClass]="formClass"
      [ovAutoService]="ovAutoService"
      [overrideAddButtonClick]="true"
      (addButtonClick)="onAddViolation()"
      [overrideEditButtonClick]="true"
      (editButtonClick)="onEditViolation($event)"
      (deleteButtonClick)="onDeleteViolation($event)"
      [excludeColumns]="4"
      [hasBulkUpload]="false"
      [hideAddButton]="false"
      [showFiller]="false"
      [showTopBar]="true"
      [filterEnabled]="false"
      [showPageSelect]="false"
      [pageChangeEnabled]="false"
      [defaultOrderDirection]="'DESC'"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>

<ov-suite-mat-dialog
  [header]="false"
  [actions]="false"
  [width]="550"
  [content]="yardTurnaroundTimeViolationDialogContent"
  [toggler]="dialogListener"
></ov-suite-mat-dialog>

<ng-template #yardTurnaroundTimeViolationDialogContent>
  <ov-suite-yard-turnaround-time-violation-dialog
    (violationEventEmitter)="onViolationEventEmitted($event)"
    [violationAlertTrigger]="selectedViolationAlertTrigger"
    [systemUsers]="systemUsers"
  ></ov-suite-yard-turnaround-time-violation-dialog>
</ng-template>
