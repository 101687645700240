<ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
<div class="main-content">
  <ov-suite-hierarchy-table
    [title]="'Workflow Processes'"
    [formClass]="formClass"
    [service]="workflowProcessService"
    [hasBulkUpload]="false"
    [excludeColumns]="4"
    [filter]="filter"
  >
  </ov-suite-hierarchy-table>
</div>
