import { Component, OnInit } from '@angular/core';
import { TrackerInformation } from '@ov-suite/models-yard';
import { OvAutoService } from '@ov-suite/services';
import { TrackerInformationService } from '../../../services/trackers/tracker-information.service';

@Component({
  selector: 'ov-suite-trackers',
  templateUrl: './trackers.component.html',
  styleUrls: ['./trackers.component.css'],
})
export class TrackersComponent implements OnInit {
  formClass = TrackerInformation;

  constructor(public readonly ovAutoService: OvAutoService, public trackerInformationService: TrackerInformationService) {}

  ngOnInit(): void {}
}
