<mat-card class="dashboard-chart">
    <mat-toolbar class="chart-toolbar">
      <mat-toolbar-row>
        <h1 class="mat-subheading-2">{{ chartTitle }}</h1>
        <span class="mat-caption">[{{ selectedDateRange }}]</span>
        <div class="spacer"></div>
        <i class="fa fa-ellipsis-h" aria-hidden="true" [matMenuTriggerFor]="menu"></i>
      </mat-toolbar-row>
    </mat-toolbar>

  <mat-menu #menu="matMenu" xPosition="before">
    <ov-suite-dynamic-date-picker
      (dateRangeEmitter)="resetDateRange($event)"
      [dateRange]="dashboardChart.getCurrentSelectedDates()"
      [pickerClass]="'local-date-picker'"
      class="individual-chart-date-picker"
    ></ov-suite-dynamic-date-picker>
  </mat-menu>

  <mat-card-content>
    <canvas baseChart
            id="chart"
            [labels]="config['labels']"
            [data]="config['data']"
            [options]="config['options']">
    </canvas>
  </mat-card-content>
</mat-card>
