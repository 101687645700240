<div class="container-fluid">
  <mat-grid-list
    [cols]="16"
    [rowHeight]="screenHeight / 10"
  >
    <mat-grid-tile
      colspan="12"
      rowspan="5"
      [style.background]="'transparent'"
    >
      <div id="geofence-map">
        <mat-card
          class="background-transparent"
        >
          <mat-card-content>
            <ov-suite-drawable-map
              *ngIf="ready"
              [center]="mapCenter"
              [geofenceList]="geofenceList"
              [drawnItems]="shapes"
              [selectedGeofence]="selectedGeofence"
              (geoJsonEventEmitter)="addGeofence($event)"
            ></ov-suite-drawable-map>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-grid-tile>

    <mat-grid-tile
      colspan="4"
      rowspan="9"
    >
      <div
        id="geofence-tile"
        *ngIf="selectedGeofence"
      >
        <mat-card id="geofence-card" class="background-white">
          <mat-card-title>
            <span class="card-title">Geofence</span>
          </mat-card-title>
          <hr>
          <mat-card-content>
            <div class="form-group">
              <label [for]="selectedGeofence.name">Name</label>
              <div [ngClass]="validation.name ? 'input-group' : 'input-group-invalid'">
                <input
                  placeholder="Geofence Name"
                  [(ngModel)]="selectedGeofence.name"
                  [value]="selectedGeofence.name"
                  [id]="selectedGeofence.name"
                  autocomplete="off"
                  [name]="selectedGeofence.name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group">
              <label [for]="selectedGeofence.description">Description</label>
              <div [ngClass]="validation.description ? 'input-group' : 'input-group-invalid'">
                <input
                  placeholder="Geofence Description"
                  [(ngModel)]="selectedGeofence.description"
                  [value]="selectedGeofence.description"
                  [id]="selectedGeofence.description"
                  autocomplete="off"
                  [name]="selectedGeofence.description"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="geofenceList">Navigate</label>
              <div class="input-group">
                <select
                  [(ngModel)]="selectedGeofence.id"
                  (ngModelChange)="onNavigateGeofence($event)"
                  [value]="selectedGeofence.id"
                  id="geofenceList"
                  name="geofenceList"
                  autocomplete="off"
                  class="form-control"
                >
                  <option value="null" disabled selected>Select Geofence</option>
                  <option
                    *ngFor="let fence of geofenceList"
                    [value]="fence.id"
                  >
                    {{ fence.name }}
                  </option>
                </select>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions class="no-margins">
            <button
              mat-button
              color="primary"
              class="fill-button"
              (click)="saveGeofence()"
            >
              Save Geofence
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-grid-tile>

    <mat-grid-tile
      *ngIf="geofenceId"
      colspan="12"
      rowspan="4"
    >
      <div id="geofence-alert-tile">
        <ov-suite-geofence-alert-management
          [selectedGeofence]="selectedGeofence"
        ></ov-suite-geofence-alert-management>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
