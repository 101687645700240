import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { Router } from '@angular/router';
import { startCase } from 'lodash';
import { QueueGroup, QueueItem, QueueModel } from '@ov-suite/models-yard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { hasFeaturePermission, PermissionAction } from '@ov-suite/authguard-angular';
import { QueryParams } from '@ov-suite/helpers-shared';
import { QueuesService } from '../../../services/queues/queues.service';
import { TableTopTabMenuItem } from '../../../components/table-top-tab-menu/table-top-tab-menu.model';
import { QueueConfigModalComponent } from '../queue-config-modal/queue-config-modal.component';

@Component({
  selector: 'ov-suite-queues-list',
  templateUrl: './queues-list.component.html',
  styleUrls: ['./queues-list.component.scss'],
})
export class QueuesListComponent implements OnInit {
  formClass = QueueItem;

  queueGroups: QueueGroup[];

  queues: QueueModel[] = [];

  queuesForActiveGroup: QueueModel[] = [];

  /**
   * Tabs at top of Screen
   */
  tabs: TableTopTabMenuItem[] = [];

  tableTabs: TableTopTabMenuItem[] = [];

  activeGroupTab: TableTopTabMenuItem;

  activeGroupId: number;

  activeTabId: number;

  queueItems: QueueItem[] = [];

  hasPermission = false;

  filter: Record<string, QueryParams[]> = {
    'queue.id': [0],
  };

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    public ovAutoService: OvAutoService,
    public queuesService: QueuesService,
    private readonly ngZone: NgZone,
    private readonly route: Router,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.fetchQueues().catch(err => console.error(err));
    this.checkPermission().catch(error => console.log({ error }));
  }

  async checkPermission() {
    this.hasPermission = await hasFeaturePermission(601, PermissionAction.UPDATE);
  }

  async fetchQueues() {
    await this.ovAutoService
      .list({
        entity: QueueModel,
        keys: [
          'id',
          'capacity',
          'name',
          'key',
          'priorityList',
          'priorityList.id',
          'priorityList.priority',
          'priorityList.priorityGroup',
          'limits',
          'limits.id',
          'limits.priorityGroup',
          'limits.priority',
          'limits.limit',
        ],
      })
      .then(queueResponse => {
        this.queues = queueResponse.data;
        this.tabs = this.getTabs();
        if (this.queues.length) {
          this.activeTabId = this.queues[0].id;
          this.filter = {
            'queue.id': [this.activeTabId],
          };
        }
      });
  }

  async fetchQueueItemsForActiveTab() {
    await this.ovAutoService
      .list({
        entity: QueueItem,
        query: {
          'queue.id': [this.activeTabId],
        },
      })
      .then(response => {
        this.queueItems = response.data;
      });
  }

  getTabs() {
    const tabArr = [];
    this.queues.forEach(queue => {
      tabArr.push({ title: startCase(queue.name), path: queue.key, key: queue.id });
    });

    return tabArr;
  }

  onTabClick(id: string): void {
    const tab = this.tabs.find(t => t.key === id);
    if (tab) {
      this.activeTabId = Number(id);
      this.filter = {
        'queue.id': [id],
      };
    }
  }

  public async openConfigModal(): Promise<void> {
    const modalRef = this.modalService.open(QueueConfigModalComponent, { size: 'xl' });
    modalRef.componentInstance.queue = this.queues.find(q => q.id === this.activeTabId);
    modalRef.componentInstance.dismissModal = modalRef;
  }
}
