<mat-dialog-content>
  <mat-card class="example-card shadow-none">
    <div class="filter-card-header">
      <div class="card-title">Date Range Picker</div>
      <span
        type="button"
        (click)="onCancel()"
      >
      <ov-suite-icons icon="cross" size="icon-md"></ov-suite-icons>
    </span>
    </div>
    <mat-card-content>
      <ngb-datepicker
        #dp
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        outsideDays="hidden">
      </ngb-datepicker>

      <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="warn" (click)="onCancel()">Cancel</button>
      <button mat-button color="accent" (click)="onApply()">Apply</button>
    </mat-card-actions>
  </mat-card>
</mat-dialog-content>
