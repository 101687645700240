import { Injectable } from '@angular/core';
import { UserRep } from '@ov-suite/models-idm';
import { Geofence, GeofenceAlert, GeofenceAlertUser } from '@ov-suite/models-yard';
import { OvAutoService } from '@ov-suite/services';

@Injectable({
  providedIn: 'root',
})
export class GeofenceAlertsService {
  constructor(private readonly ovAutoService: OvAutoService) {}

  async prepareUsers(): Promise<UserRep[]> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: UserRep,
          keys: ['id', 'firstName', 'lastName', 'domains'],
          orderColumn: 'firstName',
          orderDirection: 'ASC',
          limit: 500,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => reject(err.message));
    });
  }

  prepareGeofenceAlerts(geofenceId: number): Promise<GeofenceAlert[]> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: GeofenceAlert,
          filter: { geofenceId: [geofenceId] },
          keys: ['id', 'duration', 'geofence'],
          orderColumn: 'duration',
          orderDirection: 'ASC',
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => reject(err.message));
    });
  }

  prepareGeofenceAlertUsers(alertIdList: number[]): Promise<GeofenceAlertUser[]> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: GeofenceAlertUser,
          search: { 'geofenceAlert.id': alertIdList },
          limit: 500,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => reject(err.message));
    });
  }

  createAlert(alert: GeofenceAlert): Promise<GeofenceAlert> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .create({
          entity: GeofenceAlert,
          item: alert,
        })
        .then(createAlert => resolve(createAlert))
        .catch(err => reject(err.message));
    });
  }

  updateAlert(alert: GeofenceAlert): Promise<GeofenceAlert> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .update({
          entity: GeofenceAlert,
          item: alert,
        })
        .then(createAlert => resolve(createAlert))
        .catch(err => reject(err.message));
    });
  }

  async activateUsers(users: UserRep[], geofenceAlert: GeofenceAlert, geofence: Geofence): Promise<void> {
    users.forEach(user => {
      const newAlertUser = new GeofenceAlertUser();

      newAlertUser.user = user;
      newAlertUser.geofenceAlert = geofenceAlert;
      newAlertUser.geofenceAlert.geofence = geofence;

      this.ovAutoService
        .create({
          entity: GeofenceAlertUser,
          item: newAlertUser,
        })
        .then(() => {});
    });
  }

  async deactivateUsers(alerts: GeofenceAlertUser[]): Promise<void> {
    alerts.forEach(alert => {
      this.ovAutoService.delete(GeofenceAlertUser, 'yardlink', alert.id);
    });
  }

  async deactivateGeofenceAlert(alert: GeofenceAlert): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .delete(GeofenceAlert, 'yardlink', alert.id)
        .then(deleted => resolve(deleted))
        .catch(err => reject(err.message));
    });
  }
}
