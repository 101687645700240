import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRep } from '@ov-suite/models-idm';
import { Geofence, GeofenceAlert, GeofenceAlertUser } from '@ov-suite/models-yard';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SessionStorage } from '@ov-suite/helpers-angular';
import { GeofenceAlertsService } from '../../../services/geofence/geofence-alerts.service';
import { AlertManagementResponse } from './geofence-alert-management-dialog/geofence-alert-management-dialog.component';
import _ from 'lodash';

@Component({
  selector: 'ov-suite-geofence-alert-management',
  templateUrl: './geofence-alert-management.component.html',
  styleUrls: ['./geofence-alert-management.component.scss'],
})
export class GeofenceAlertManagementComponent implements OnChanges {
  geofenceId: number;

  @Input() selectedGeofence: Geofence;

  domainId: number;

  /**
   * List of all available users assigned to this domain.
   */
  userList: UserRep[];

  /**
   * List of all geofence alerts assigned to this geofence.
   *
   * Was set up in a way that allows for duplicates.
   */
  geofenceAlertList: GeofenceAlert[];

  /**
   * List of users that has been allocated to this geofence.
   *
   * This will require a list of geofence alert id's.
   */
  geofenceAlertUserList: GeofenceAlertUser[];

  routesSubscription: Subscription;

  /**
   * Controls the dialog.
   */
  dialogListener: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  selectedAlert: GeofenceAlert;

  selectedAlertActiveUsers: GeofenceAlertUser[];

  geofenceAlertIdList: number[];

  constructor(private readonly geofenceAlertService: GeofenceAlertsService, private readonly route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedGeofence) {
      this.geofenceId = changes.selectedGeofence.currentValue.id;

      this.domainId = Number(SessionStorage.getSelectedDomain());

      this.preparePageInformation().then();
    }
  }

  async preparePageInformation() {
    this.userList = await this.geofenceAlertService.prepareUsers();

    this.geofenceAlertList = await this.geofenceAlertService.prepareGeofenceAlerts(this.geofenceId);

    this.geofenceAlertIdList = this.geofenceAlertList.map(i => i.id);

    this.geofenceAlertUserList = await this.prepareGeofenceAlertUserList(this.geofenceAlertIdList);
  }

  async prepareSaveDialogResponse(response: AlertManagementResponse): Promise<void> {
    const { alert, activateUsers, deactivateUsers } = response;

    try {
      // Step 1. Save/Update the geofence
      if (alert) {
        const alertC = alert as GeofenceAlert;
        if (!alertC.id) {
          // Creating a new geofenceAlert
          await this.geofenceAlertService
            .createAlert(alertC)
            .then(alertResult => {
              this.geofenceAlertList.push(alertResult);
              this.selectedAlert = alertResult;
            })
            .catch(err => console.error(err.message));
        } else {
          // Updating geofenceAlert
          await this.geofenceAlertService
            .updateAlert(alertC)
            .then(alertResult => {
              this.selectedAlert = alertResult;
            })
            .catch(err => console.error(err.message));
        }
      }

      /**
       * Step 2: Save the active users
       *
       * Needs the selected geofenceAlertId to proceed.
       */
      if (activateUsers) {
        await this.geofenceAlertService.activateUsers(activateUsers, this.selectedAlert, this.selectedGeofence);
      }

      /**
       * Step 3: Save the inactive users
       *
       * Needs the selected geofenceAlertId to proceed.
       */
      if (deactivateUsers) {
        // We can filter out the users from the active users to remove them from the active state.
        const usersToDeactivate = this.selectedAlertActiveUsers.filter(item => deactivateUsers.indexOf(item.user as UserRep) > -1);

        await this.geofenceAlertService.deactivateUsers(usersToDeactivate);
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      // Call this again to get the latest changes to the active user list.
      this.preparePageInformation().then(() => {
        this.dialogListener.next(false);
      });
    }
  }

  /**
   * Gets a list alert users for this specific geofence.
   *
   * @param alertIdList
   */
  prepareGeofenceAlertUserList(alertIdList: number[]) {
    return this.geofenceAlertService.prepareGeofenceAlertUsers(alertIdList);
  }

  /**
   * Creates a new alert that can be manipulated in the dialog.
   */
  createAlert(): void {
    const alert = new GeofenceAlert();

    alert.duration = null;
    alert.geofence = this.selectedGeofence;

    this.selectedAlert = _.clone(alert);

    // As this alert is new, there will not be any activated users.
    this.selectedAlertActiveUsers = [];

    this.dialogListener.next(true);
  }

  /**
   * Loads the new selected alert to the edited in the dialog.
   *
   * @param alert
   */
  editAlert(alert: GeofenceAlert): void {
    this.selectedAlert = _.clone(alert);

    this.selectedAlert.geofence = this.selectedGeofence;

    // Filters out the activated users for this specific geofence alert.
    this.selectedAlertActiveUsers = this.geofenceAlertUserList.filter(user => user.geofenceAlert.id === alert.id);

    this.dialogListener.next(true);
  }

  async deleteAlert(alert: GeofenceAlert) {
    const usersToDeactivate = this.geofenceAlertUserList.filter(user => user.geofenceAlert.id === alert.id);

    await this.geofenceAlertService.deactivateGeofenceAlert(alert).then(() => {
      this.geofenceAlertService.deactivateUsers(usersToDeactivate).then(() => {
        this.preparePageInformation();
      });
    });
  }

  onAlertManagementDialogEventEmitter(alertManagementDialogResponse: false | AlertManagementResponse) {
    if (alertManagementDialogResponse === false) {
      this.dialogListener.next(false);
      return;
    }

    this.prepareSaveDialogResponse(alertManagementDialogResponse).then(() => {});
  }
}
