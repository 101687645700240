import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService, OvAutoServiceListParams } from '@ov-suite/services';
import gql from 'graphql-tag';
import { mapToClass, PageReturn } from '@ov-suite/ov-metadata';
import { listQueryKeys } from '@ov-suite/graphql-helpers';
import { ExitEntryEvent } from '@ov-suite/models-yard';

@Injectable()
export class ExitEntryEventService extends AutoService<ExitEntryEvent> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), ExitEntryEvent, 'ExitEntryEvent', 'ExitEntryEvents', 'yardlink');
  }

  async getLiveFeedWithoutGeofence(params: Omit<OvAutoServiceListParams<ExitEntryEvent>, 'entity'>): Promise<PageReturn<ExitEntryEvent>> {
    const { offset = 0, limit = 100, query, search, filter, keys = [], orderColumn = 'id', orderDirection = 'DESC' } = params;
    const name = 'getLiveFeedWithoutGeofence';
    return new Promise((resolve, reject) => {
      this.apollo
        .use('yardlink')
        .query({
          query: listQueryKeys({
            name,
            input: ExitEntryEvent,
            metadata: this.metadata,
            specificKeys: keys,
            api: 'yardlink',
          }),
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search,
              filter,
              query,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => this.mapPageReturnExitEntryEvent(response, name, resolve), reject);
    });
  }

  async getLiveFeedWithGeofence(params: Omit<OvAutoServiceListParams<ExitEntryEvent>, 'entity'>): Promise<PageReturn<ExitEntryEvent>> {
    console.log(params);
    const { offset = 0, limit = 100, query, search, filter, keys = [], orderColumn = 'id', orderDirection = 'DESC' } = params;
    const searchKeysToIgnore = ['exitTime', 'entryTime', 'arrivalTime'];
    const searchKeys = {};
    Object.keys(search)
      .filter(key => !searchKeysToIgnore.includes(key))
      .forEach(key => {
        searchKeys[key] = search[key];
      });
    return new Promise((resolve, reject) => {
      this.apollo
        .use('yardlink')
        .query({
          query: gql`
            query getLiveFeedWithGeofence($params: ListParamsInput!) {
              getLiveFeedWithGeofence(params: $params) {
                totalCount
                data {
                  id
                  identityDisplayName
                  entryTime
                  exitTime
                  arrivalTime
                  accessCode
                  registrationNumber
                  entryUser
                  exitUser
                  exitEntryEventStatus {
                    id
                    color
                    name
                  }
                  processInstanceId
                  reason
                  imei
                  entryType
                  extraColumns {
                    title
                    value
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search: searchKeys,
              filter,
              query,
            },
          },
        })

        .subscribe(response => this.mapPageReturn(response, resolve, 'getLiveFeedWithGeofence'), reject);
    });
  }

  async getArrivals(params: Omit<OvAutoServiceListParams<ExitEntryEvent>, 'entity'>): Promise<PageReturn<ExitEntryEvent>> {
    console.log(params);
    const { offset = 0, limit = 100, query, search, filter, keys = [], orderColumn = 'id', orderDirection = 'DESC' } = params;
    return new Promise((resolve, reject) => {
      this.apollo
        .use('yardlink')
        .query({
          query: gql`
            query getArrivals($params: ListParamsInput!) {
              getArrivals(params: $params) {
                totalCount
                data {
                  id
                  identityDisplayName
                  entryTime
                  exitTime
                  arrivalTime
                  accessCode
                  registrationNumber
                  entryUser
                  exitUser
                  exitEntryEventStatus {
                    id
                    color
                    name
                  }
                  geofence
                  processInstanceId
                  reason
                  imei
                  entryType
                  extraColumns {
                    title
                    value
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search,
              filter,
              query,
            },
          },
        })

        .subscribe(response => this.mapPageReturn(response, resolve, 'getArrivals'), reject);
    });
  }

  mapPageReturn(response, resolve, name) {
    const rawData = response.data[name];
    const { totalCount } = rawData;
    const data = rawData.data.map(item => {
      return mapToClass(ExitEntryEvent, item);
    });
    resolve({ data, totalCount });
  }

  mapPageReturnExitEntryEvent(response, name, resolve) {
    const rawData = response.data[name].data;
    const { totalCount } = response.data[name];
    const data = rawData.map(item => mapToClass(ExitEntryEvent, item));
    resolve({ data, totalCount });
  }

  async delete(id: number | string): Promise<void> {
    return new Promise(resolve => {
      this.apollo
        .use('yardlink')
        .mutate({
          mutation: gql`
            mutation softDeleteLiveFeedEntry($id: Float!) {
              softDeleteLiveFeedEntry(id: $id)
            }
          `,
          variables: { id },
        })
        .subscribe(() => {
          resolve();
        });
    });
  }
}
