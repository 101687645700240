import { ChartData, ChartOptions } from 'chart.js';
import _ from 'lodash';

export class OvSuiteChart {
  constructor(
    private readonly name: string,
    private readonly data: ChartData,
    private readonly options: ChartOptions,
    private readonly type: string,
    private readonly serviceMethod: string,
    public widgets?: DashboardWidget[],
  ) {}

  /**
   * Updates the data portion of the chart.
   */
  update(value: number[]): void {
    if (value[0]) {
      Object.keys(value[0]).forEach(key => {
        const formatted = _.map(value, key);

        if (key === 'label') {
          this.data.labels = formatted;
        } else {
          const updateSet = this.data.datasets.find(set => set.label === key);
          if (updateSet) {
            updateSet.data = formatted;
          }
        }
      });
    }
  }

  getData(): ChartData {
    return this.data;
  }

  getOptions(): ChartOptions {
    return this.options;
  }

  getLabels(): unknown[] {
    return this.data.labels;
  }

  getName(): string {
    return this.name;
  }

  getServiceMethod(): string {
    return this.serviceMethod;
  }

  // Returns the current Chart Object
  getChart(): OvSuiteChart {
    return this;
  }
}

export class DashboardWidget {
  constructor(public id, public title, public subtitle, public icon) {}

  updateAverage(average: string): void {
    this.setAverage(average);
  }

  setAverage(average: string): void {
    this.title = average;
  }
}
