import { Routes } from '@angular/router';
import { FormBuilderListComponent } from './list/form-builder-list.component';
import { FormBuilderAddOrEditComponent } from './add-or-edit/form-builder-add-or-edit.component';

export const AreaRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FormBuilderListComponent
      },
      {
        path: 'add',
        component: FormBuilderAddOrEditComponent
      },
      {
        path: 'edit',
        component: FormBuilderAddOrEditComponent
      }
    ]
  }
];
