<div class="modal-header">
  <h5 class="modal-title">Update Capacity Limits - {{queue.name}}</h5>
</div>
<div class="modal-body modal-lg">
  <div *ngIf="queue" class="d-flex justify-content-between">
    <h6 style="flex: 1;">Total Limit</h6>
    <input type="number" class="form-control form-group" [(ngModel)]="queue.capacity" style="max-width: 70px" min="0" />
    <span style="margin: 5px 5px 5px 20px" (click)="clearQueue()">clear</span>
  </div>
  <h6>Priority Group Limits</h6>
  <div *ngFor="let pg of groupLimits" class="d-flex justify-content-between">
    <label class='px-2' style="flex: 1;">{{pg.priorityGroup}}</label>
    <input type="number" class="form-control form-group" [(ngModel)]="pg.limit" style="max-width: 70px" min="0"/>
    <span style="margin: 5px 5px 5px 20px" (click)="clear(pg)">clear</span>
  </div>
  <h6>Priority Limits</h6>
  <div *ngFor="let pl of priorityLimits" class="d-flex justify-content-between">
    <label class='px-2' style="flex: 1;">{{pl.priority}}</label>
    <input type="number" class="form-control form-group" [(ngModel)]="pl.limit" style="max-width: 70px" min="0"/>
    <span style="margin: 5px 5px 5px 20px" (click)="clear(pl)">clear</span>
  </div>
  <h6>Priority & Priority Group Limits</h6>
  <div *ngFor="let cl of comboLimits" class="d-flex justify-content-between">
    <label class='px-2' style="flex: 1;">{{cl.priority}} - {{cl.priorityGroup}} </label>
    <input type="number" class="form-control form-group" [(ngModel)]="cl.limit" style="max-width: 70px" min="0"/>
    <span style="margin: 5px 5px 5px 20px" (click)="clear(cl)">clear</span>
  </div>
<!--  <div *ngIf="queues.length">-->
<!--    <div *ngFor="let item of queues" class="d-flex justify-content-between">-->
<!--      <label>{{item.name}}</label>-->
<!--      <input type="number" class="form-control form-group" placeholder="Capacity" [(ngModel)]="item.capacity" />-->
<!--    </div>-->
<!--  </div>-->
  <span *ngIf='errorMessage' class='text-danger align-self-center'>{{errorMessage}}</span>
  <div class="modal-body modal-lg d-flex justify-content-between">
    <button class="btn btn-simple" (click)="onCancel()">Cancel</button>
    <button class="btn btn-primary" (click)="onSave()">Save</button>
  </div>
</div>
