<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"></ov-suite-hierarchy-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Entered & Departed Collections'"
      [formClass]="formClass"
      [service]="exitEntryEventService"
      [excludeColumns]="4"
      [hasBulkUpload]="false"
      [hideAddButton]="true"
      [overrideServiceMethod]="'getLiveFeedWithoutGeofence'"
      [hideColumnKeys]="['geofence','entryUser', 'exitUser']"
      [showFiller]="false"
      [showTopBar]="true"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
