import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LiveMapDashboardComponent } from './dashboard/live-map-dashboard.component';
import { VehicleTrackYardService } from '../../services/vehicle-track/vehicle-track.service';
import { LiveMapDashboardRoutes } from './live-map-dashboard.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(LiveMapDashboardRoutes),
    FormsModule,
    UiModule,
    LeafletModule,
  ],
  declarations: [LiveMapDashboardComponent],
  providers: [VehicleTrackYardService],
  exports: [LiveMapDashboardComponent],
})
export class LiveMapDashboardModule {}
