import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRep } from '@ov-suite/models-idm';
import { GeofenceAlert, GeofenceAlertUser } from '@ov-suite/models-yard';
import _ from 'lodash';

export interface AlertManagementResponse {
  alert?: GeofenceAlert;
  activateUsers?: UserRep[];
  deactivateUsers?: UserRep[];
}

@Component({
  selector: 'ov-suite-geofence-alert-management-dialog',
  templateUrl: './geofence-alert-management-dialog.component.html',
  styleUrls: ['./geofence-alert-management-dialog.component.css'],
})
export class GeofenceAlertManagementDialogComponent implements OnInit {
  @Output()
  alertEventEmitter: EventEmitter<AlertManagementResponse | false> = new EventEmitter<AlertManagementResponse | false>();

  @Input() allUsers: UserRep[];

  @Input() activeUsers: GeofenceAlertUser[];

  @Input() alert: GeofenceAlert;

  originalAlert: GeofenceAlert;

  activatedUsers: UserRep[];

  ogActiveUsers: UserRep[];

  searchActivatedUsers: UserRep[];

  inactiveUsers: UserRep[];

  ogInactiveUsers: UserRep[];

  searchInactiveUsers: UserRep[];

  userSearch: string;

  searching = false;

  validate = {
    duration: true,
  };

  ngOnInit(): void {
    this.originalAlert = _.clone(this.alert);

    this.prepareUsers();
  }

  prepareUsers(): void {
    // For activate users
    this.activatedUsers = this.activeUsers ? this.activeUsers.map(i => i.user as UserRep) : [];

    this.searchActivatedUsers = this.activatedUsers;

    this.ogActiveUsers = _.clone(this.activatedUsers);

    // For inactive users
    this.inactiveUsers = this.allUsers.filter(user => !this.activatedUsers.find(i => i.id === user.id));

    this.searchInactiveUsers = this.inactiveUsers;

    this.ogInactiveUsers = _.clone(this.inactiveUsers);
  }

  /**
   * Search active and deactivated users on each key press.
   * @param e
   */
  onUserSearch(e): void {
    this.searching = e.target.value.length > 0;

    this.userSearch = e.target.value.toLowerCase();

    this.setSearchList();
  }

  /**
   * Triggered by deactivating a user in the modal.
   * @param user
   */
  deactivateUser(user: UserRep): void {
    const uIndex = this.activatedUsers.indexOf(user);

    this.activatedUsers.splice(uIndex, 1);

    this.inactiveUsers.push(user);

    if (this.searching) {
      this.setSearchList();
    }
  }

  /**
   * Triggered by activating user in the modal.
   * @param user
   */
  activateUser(user: UserRep): void {
    const uIndex = this.inactiveUsers.indexOf(user);

    this.inactiveUsers.splice(uIndex, 1);

    this.activatedUsers.push(user);

    if (this.searching) {
      this.setSearchList();
    }
  }

  setSearchList() {
    this.searchActivatedUsers = this.activatedUsers.filter(i => `${i.firstName} ${i.lastName}`.toLowerCase().includes(this.userSearch));

    this.searchInactiveUsers = this.inactiveUsers.filter(i => `${i.firstName} ${i.lastName}`.toLowerCase().includes(this.userSearch));
  }

  closeDialog(): void {
    this.alertEventEmitter.next(false);
  }

  /**
   * Emit a new value that is listened to by parent.
   */
  onSave(): void {
    this.validate.duration = !!this.alert.duration;

    if (this.validate.duration) {
      /**
       * False: nothing changed
       */
      const alertManagementDialogResponse: AlertManagementResponse = {};

      if (this.originalAlert !== this.alert) {
        alertManagementDialogResponse.alert = this.alert;
      }

      if (this.ogActiveUsers !== this.activatedUsers) {
        alertManagementDialogResponse.activateUsers = this.prepareUsersToActivate();
      }

      if (this.ogInactiveUsers !== this.inactiveUsers) {
        alertManagementDialogResponse.deactivateUsers = this.prepareUsersToDeactivate();
      }

      this.alertEventEmitter.next(alertManagementDialogResponse);
    }
  }

  /**
   * Filters newly activate users.
   */
  prepareUsersToActivate(): UserRep[] {
    return this.activatedUsers.filter(user => this.ogActiveUsers.indexOf(user as UserRep) === -1);
  }

  /**
   * Filters newly deactivated users.
   */
  prepareUsersToDeactivate(): UserRep[] {
    return this.inactiveUsers.filter(user => this.ogInactiveUsers.indexOf(user as UserRep) === -1);
  }
}
