import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { HistoricalExtraColumnsModel } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: HistoricalExtraColumnsModel,
      api: 'yardlink',
      createTitle: 'Add Historic Table Extra Columns Configuration',
      route: ['historical-extra-columns'],
      tableTitle: 'Historic Table Extra Columns Configuration',
      hideImport: true,
      showFiller: true,
      defaultOrderDirection: 'DESC',
    }),
  ],
})
export class HistoricalExtraColumnsModule {}
