import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from '@ov-suite/ui';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { YardTurnaroundTimeViolationRouting } from './yard-turnaround-time-violation.routing';
import { YardTurnaroundTimeViolationComponent } from './yard-turnaround-time-violation/yard-turnaround-time-violation.component';
import { YardTurnaroundTimeViolationDialogComponent } from './yard-turnaround-time-violation/yard-turnaround-time-violation-dialog/yard-turnaround-time-violation-dialog.component';

@NgModule({
  declarations: [YardTurnaroundTimeViolationComponent, YardTurnaroundTimeViolationDialogComponent],
  imports: [CommonModule, RouterModule.forChild(YardTurnaroundTimeViolationRouting), UiModule, MatCardModule, MatButtonModule, FormsModule],
})
export class YardTurnaroundTimeViolationModule {}
