import { FormioModel } from '@ov-suite/helpers-shared';
import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { getQueryParams, getRouterLink } from './form.utils';

@OVEntity('Form', 'shared')
@OVTable([
  {
    title: 'Key',
    key: 'key',
    type: 'string'
  },
  {
    type: 'buttons',
    title: 'Fast Actions',
    buttons: [
      {
        classes: 'btn-primary btn-sm fa fa-cog',
        routerLink: getRouterLink,
        queryParams: getQueryParams
      }
    ],
    keys: []
  }
])
export class Form {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  key: string;

  @OVField({ type: 'json' })
  json: FormioModel;
}
