import { Component, OnInit } from '@angular/core';
import { YardWorkflowProcess } from '@ov-suite/models-yard';
import { HierarchyTab } from '@ov-suite/ui';
import { ActivatedRoute } from '@angular/router';
import { YardWorkflowProcessService } from '../../../services/yard-workflow-process/yard-workflow-process.service';

@Component({
  selector: 'ov-suite-workflow-process-list',
  templateUrl: './workflow-process-list.component.html',
  styleUrls: ['./workflow-process-list.component.scss'],
})
export class WorkflowProcessListComponent implements OnInit {
  // Class - Required
  formClass = YardWorkflowProcess;

  filter: Record<string, string[]>;

  tabs: HierarchyTab[] = [
    { title: 'Workflow Processes', iconClass: '', path: '/workflow-processes' },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    public readonly workflowProcessService: YardWorkflowProcessService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      const id = param['id'];
      if (id) {
        this.filter = { processDefinitionKey: param['id'] };
      }
    });
  }
}
