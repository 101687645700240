<mat-card class="dashboard-widget">
  <mat-card-title-group>
    <mat-card-title>
      <h1 class="mat-headline">{{ widget.title }}</h1>
    </mat-card-title>
    <mat-card-subtitle class="widget-subtitle">
      <p class="mat-caption">{{ widget.subtitle }}</p>
    </mat-card-subtitle>
    <div class="widget-icon-container">
      <em [ngClass]="[widget.icon, 'fa-2x', 'widget-icon']"></em>
    </div>
  </mat-card-title-group>
</mat-card>
