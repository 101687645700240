<div class="main-content">
  <div class="card p-4">
    <div class="d-flex justify-content-between">
      <div class="col-md-8">
        <div class="left-side">
          <div class="card left-side">
            <div class="card-body">
              <div class="map"
                   leaflet
                   [leafletFitBounds]="bounds"
                   [leafletOptions]="options"
                   leafletDraw
                   [leafletDrawOptions]="drawOptions"
                   (leafletMapReady)="mapReady($event)"
                   (leafletDrawCreated)="onDrawCreated($event)"
              >
                <div [leafletLayer]="drawnItems"></div>
                <div [leafletLayer]="mainItems"></div>
                <div [leafletLayer]="imeiLine"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="right-side">
          <div class="card right-side">
            <div class="card-body">
              <h5 class="card-title mx-3">Mock Position</h5>
              <hr>

              <div class="card-body">
                <div class="row align-items-end">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="mt-2" for="name">IMEI <span aria-hidden="true" placement="right-top" class="fa fa-info-circle"></span></label>
                        <div class="input-parent">
                          <div>
                            <input class="form-control" id="name" type="text" placeholder="Required" [(ngModel)]="imei" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-end">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="mt-2" for="name">Geofences (select to navigate) <span aria-hidden="true" placement="right-top" class="fa fa-info-circle"></span></label>
                        <div class="input-parent">
                          <div>
                            <select class="form-control custom-border-styling"
                                    name="geofenceSelect"
                                    (ngModelChange)="onChangeGeofence($event)"
                                    [(ngModel)]="selectedGeofence"
                                    id="selectGeofence">
                              <option *ngFor="let c of otherGeofences" [ngValue]="c">{{c.name}}</option>

                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="btn btn-primary" (click)="saveLocation()">Save Location</button>
                  <button class="btn btn-primary" (click)="loadIMEILocations()">Load IMEI Locations</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
