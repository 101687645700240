import { Routes } from '@angular/router';
import { AdminLayoutComponent, RouteInfo } from '@ov-suite/ui';
import { mobileRoutes } from '@ov-suite/modules-angular';
import { AuthGuard } from '@ov-suite/authguard-angular';

export const angularRoutes: Routes = [
  { path: '', redirectTo: 'statistics', pathMatch: 'full' },
  {
    path: 'unauthorized',
    loadChildren: () => import('@ov-suite/authguard-angular').then(m => m.UnauthorizedModule),
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      ...mobileRoutes,
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'access-code',
        loadChildren: () => import('./modules/access-code/access-code.module').then(m => m.AccessCodeModule),
      },
      {
        path: 'live-feed',
        loadChildren: () => import('./modules/live-feed/live-feed.module').then(m => m.LiveFeedModule),
      },
      {
        path: 'entered-departed-collections',
        loadChildren: () =>
          import('./modules/entered-departed-collections/entered-departed-collections.module').then(
            m => m.EnteredDepartedCollectionsModule,
          ),
      },
      {
        path: 'historical',
        loadChildren: () => import('./modules/exit-entry-event/exit-entry-event.module').then(m => m.ExitEntryEventModule),
      },
      {
        path: 'historical-extra-columns',
        loadChildren: () =>
          import('./modules/historical-extra-columns/historical-extra-columns.module').then(m => m.HistoricalExtraColumnsModule),
      },
      {
        path: 'live-feed-extra-columns',
        loadChildren: () =>
          import('./modules/live-feed-extra-columns/live-feed-extra-columns.module').then(m => m.LiveFeedExtraColumnsModule),
      },
      {
        path: 'entry-reason',
        loadChildren: () => import('./modules/entry-reason/entry-reason.module').then(m => m.EntryReasonModule),
      },
      {
        path: 'exit-reason',
        loadChildren: () => import('./modules/exit-reason/exit-reason.module').then(m => m.ExitReasonModule),
      },
      {
        path: 'stakeholder-location',
        loadChildren: () => import('./modules/stakeholder-location/stakeholder-location.module').then(m => m.StakeholderLocationModule),
      },
      {
        path: 'live-map',
        loadChildren: () => import('./modules/live-map/live-map-dashboard.module').then(m => m.LiveMapDashboardModule),
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics-dashboard.module').then(m => m.StatisticsDashboardModule),
      },
      {
        path: 'geofence',
        loadChildren: () => import('./modules/geofence/geofence.module').then(m => m.GeofenceModule),
      },
      {
        path: 'geofence/alert',
        loadChildren: () => import('./modules/geofence/alert/geofence-alert.component').then(m => m.GeofenceAlertModule),
      },
      {
        path: 'geofence-notification-config',
        loadChildren: () =>
          import('./modules/geofence-notification-config/geofence-notification-config.module').then(
            m => m.GeofenceNotificationConfigModule,
          ),
      },
      {
        path: 'workflow-processes',
        loadChildren: () => import('./modules/workflow-process/workflow-process.module').then(m => m.WorkflowProcessModule),
      },
      {
        path: 'queues',
        loadChildren: () => import('./modules/queues/queues.module').then(m => m.QueuesModule),
      },
      {
        path: 'mock-position',
        loadChildren: () => import('./modules/mock-position/mock-position.module').then(m => m.MockPositionModule),
      },
      {
        path: 'exceptions',
        loadChildren: () => import('./modules/exceptions/exceptions.module').then(m => m.ExceptionsModule),
      },
      {
        path: 'trackers',
        loadChildren: () => import('./modules/trackers/trackers.module').then(m => m.TrackersModule),
      },
      {
        path: 'site-violations',
        loadChildren: () =>
          import('./modules/yard-turnaround-time-violation/yard-turnaround-time-violation.module').then(
            m => m.YardTurnaroundTimeViolationModule,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'statistics',
  },
];

export const sidebarRoutes: RouteInfo[] = [
  {
    path: '/',
    title: 'dashboard',
    type: 'sub',
    collapse: 'dashboard',
    // icontype: 'ovico-dashboard',
    icontype: 'fa fa-tachometer',
    children: [
      { path: 'live-feed', title: 'Live Feed', ab: 'LF' },
      { path: 'historical', title: 'Historical', ab: 'HT' },
      { path: 'live-map', title: 'Live Map', ab: 'LM' },
      { path: 'statistics', title: 'Statistics', ab: 'ST' },
    ],
  },
  {
    path: '/',
    title: 'Queue Management',
    type: 'sub',
    collapse: 'Queue-management',
    icontype: 'fa fa-tasks',
    children: [{ path: 'queues', title: 'Queues', ab: 'Q', permissionId: 601 }],
  },
  {
    path: '/',
    title: 'Health Monitoring',
    type: 'sub',
    collapse: 'Health-monitoring',
    icontype: 'fa fa-medkit',
    children: [
      { path: 'exceptions', title: 'Exceptions', ab: 'E', permissionId: 602 },
      { path: 'trackers', title: 'Trackers', ab: 'T', permissionId: 602 },
    ],
  },
  {
    path: '/',
    title: 'General setup',
    type: 'sub',
    collapse: 'general-setup',
    // icontype: 'ovico-settings',
    icontype: 'fa fa-sliders',
    children: [
      { path: 'access-code', title: 'Access Code', ab: 'AC', permissionId: 602 },
      { path: 'geofence', title: 'Geofence Setup', ab: 'GS', permissionId: 602 },
      { path: 'site-violations', title: 'Site Violations', ab: 'SV', permissionId: 602 },
      { path: 'live-feed-extra-columns', title: 'Live Feed Columns', ab: 'LC', permissionId: 602 },
      { path: 'historical-extra-columns', title: 'Historical Columns', ab: 'HC', permissionId: 602 },
    ],
  },
];
