import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { YardTurnaroundTimeViolationUserModel } from '@ov-suite/models-yard';

@Injectable({
  providedIn: 'root',
})
export class YardTurnaroundTimeViolationUsersService {

  constructor(public readonly ovAutoService: OvAutoService) { }

  /**
   * Gives us all users that are registered on this yard violation.
   */
  prepareActivatedUsers(violationId: number): Promise<YardTurnaroundTimeViolationUserModel[]> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: YardTurnaroundTimeViolationUserModel,
          keys: ['id', 'active', 'userId', 'violationId'],
          filter: { violationId: [violationId] },
          orderColumn: 'userId',
          orderDirection: 'ASC',
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => reject(err.message));
    });
  }
}
