<div class="leaflet-map">
  <div>
    <div class="card slide-in-right">
        <div class="card-body">
          <div class="map"
               leaflet
               [leafletFitBounds]="bounds"
               [leafletOptions]="options"
               (leafletMapReady)="mapReady($event)"
               (leafletMapMove)="mapMove()"
               (leafletMapZoom)="mapZoom()"
          >
          </div>
        </div>
    </div>
  </div>
</div>


