<div class="geofence-alert-setup">
  <mat-card class="background-transparent shadow-none">
    <mat-card-title class="card-title">Configure Alert Trigger</mat-card-title>
    <mat-card-content>
      <mat-card-title class="table-title">Duration <em>(minutes)</em></mat-card-title>

      <div class="form-group">
        <div [ngClass]="validate.duration ? 'input-group' : 'input-group-invalid'">
          <input
            [(ngModel)]="alert.duration"
            placeholder="Triggered After"
            id="triggerDuration"
            autocomplete="off"
            name="triggerDuration"
            type="number"
            class="form-control"
          />
        </div>
      </div>
    </mat-card-content>

    <hr>

    <mat-card-content>
      <mat-card-title class="table-title">Search Users</mat-card-title>

      <div class="form-group">
        <div class="input-group">
          <input
            id="userSearch"
            autocomplete="off"
            name="userSearch"
            type="text"
            class="form-control"
            placeholder="Search Users"
            (keyup)="onUserSearch($event)"
          />
        </div>
      </div>
    </mat-card-content>

    <hr>

    <mat-card-content>
      <mat-card-title class="table-title">Activated Users</mat-card-title>

      <table
        class="table table-sm table-bordered"
        [ngClass]="'table-striped'"
        aria-describedby="Link Table"
      >
        <thead>
          <th>User</th>
          <th>Deactivate</th>
        </thead>

        <tbody>
          <tr *ngFor="let activeUser of searchActivatedUsers">
            <td>{{ activeUser.firstName }} {{ activeUser.lastName }}</td>
            <td>
              <button
                mat-icon-button
                (click)="deactivateUser(activeUser)"
              >
                <em class="fa fa-minus icon-danger"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>

    <hr>

    <mat-card-content>
      <mat-card-title class="table-title">Inactive Users</mat-card-title>
      <table
        class="table table-sm table-bordered"
        [ngClass]="'table-striped'"
        aria-describedby="Link Table"
      >
        <thead>
          <th>User</th>
          <th>Activate</th>
        </thead>

        <tbody>
          <tr *ngFor="let inactiveUser of searchInactiveUsers;">
            <td>{{ inactiveUser.firstName }} {{ inactiveUser.lastName }}</td>
            <td>
              <button
                mat-icon-button
                (click)="activateUser(inactiveUser)"
              >
                <em class="fa fa-plus icon-success"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="warn" (click)="closeDialog()">Cancel</button>
      <button mat-button color="primary" (click)="onSave()">Save</button>
    </mat-card-actions>
  </mat-card>
</div>
