import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard-v2/dashboard.component';


export const StatisticsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  }
];
