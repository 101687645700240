import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { QueueItem } from '@ov-suite/models-yard';
import gql from 'graphql-tag';

@Injectable()
export class QueuesService extends AutoService<QueueItem> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), QueueItem, 'QueueItem', 'QueueItems', 'yardlink');
  }

  async delete(id: number | string): Promise<void> {
    return new Promise(resolve => {
      this.apollo
        .use('yardlink')
        .mutate({
          mutation: gql`
            mutation dequeueEntry($id: Float!) {
              dequeueEntry(id: $id)
            }
          `,
          variables: { id },
        })
        .subscribe(() => {
          resolve();
        });
    });
  }
}
