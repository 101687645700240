import { Routes } from '@angular/router';
import { QueuesListComponent } from './queues-list/queues-list.component';

export const QueuesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: QueuesListComponent,
      },
    ],
  },
];
