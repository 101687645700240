import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ov-suite-date-range-picker-new',
  templateUrl: './date-range-picker.component.html',
  styleUrls: [ './date-range-picker.component.css' ]
})
export class DateRangePickerComponentNew implements OnInit {
  fromDate: NgbDateStruct;

  toDate: NgbDateStruct | null = null;

  hoveredDate: NgbDate | null = null;

  constructor(
    calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private readonly dialogRef: MatDialogRef<DateRangePickerComponentNew>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.fromDate = this.formatter.parse(data.fromDate.toISOString());
    this.toDate = this.formatter.parse(data.toDate.toISOString());
  }

  ngOnInit(): void {}

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  onApply() {
    const from = new Date(this.formatter.format(this.fromDate));
    const to = new Date(this.formatter.format(this.toDate));

    if (to > from) {
      this.dialogRef.close({
        fromDate: from,
        toDate: to,
      });
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
