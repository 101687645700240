import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment, setEnvironment } from '@ov-suite/helpers-angular';
import Amplify from '@aws-amplify/core';
import moment from 'moment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
  return moment(this).format();
};

fetch('/assets/config.json', {
  method: 'GET',
})
  .then(response => (response.ok ? response.json() : Promise.reject(response)))
  .then(data => {
    localStorage.setItem('config', JSON.stringify(data));

    if (data.production) {
      enableProdMode();
    }
    setEnvironment(data);

    Sentry.init({
      dsn: 'https://0b7b4582bf074070bce339393d86b0c5@sentry.linksuite.io/2',
      integrations: [
        // @ts-ignore
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', environment.values.apiUrl.idm],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      release: 'yardlink-web-1.2.3',
      environment: environment?.values?.webUrl?.admin ? environment.values.webUrl.idm : 'dev',
      tracesSampleRate: 1.0,
    });

    Amplify.configure({
      Auth: {
        identityPoolId: data.cognito.identityPoolId,
        region: data.cognito.region,
        userPoolId: data.cognito.userPoolId,
        userPoolWebClientId: data.cognito.clientId,
        cookieStorage: {
          domain: data.domain,
          secure: data.domain !== 'localhost',
          path: '/',
          expires: 365,
        },
      },
      aws_project_region: data.cognito.region,
      aws_cognito_identity_pool_id: data.cognito.identityPoolId,
      aws_cognito_region: data.cognito.region,
      aws_user_pools_id: data.cognito.userPoolId,
      aws_user_pools_web_client_id: data.cognito.clientId,
      oauth: data.cognito.oauth,
      aws_user_files_s3_bucket: data.cognito.bucketId,
      aws_user_files_s3_bucket_region: data.cognito.region,
    });

    // (document.getElementById('auth-script') as HTMLScriptElement).src = `${data.webUrl.idm}/auth.js`;
    // (document.getElementById('auth-frame') as HTMLIFrameElement).src = `${data.webUrl.idm}/auth.html`;

    platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch(error => {
    console.error('Something went wrong.', error);
  });
