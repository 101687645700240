import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { VehicleTrack } from '@ov-suite/models-yard';
import gql from 'graphql-tag';

@Injectable()
export class VehicleTrackYardService extends AutoService<VehicleTrack> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), VehicleTrack, 'VehicleTrack', 'VehicleTracks', 'yardlink');
  }

  getLiveMap() {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        {
          getLiveMap {
            imei
            geojson
            geofence
            currentLon
            currentLat
            entryTimestamp
            accessCode
            registrationNumber
            ragStatus
            metadata
            battery
          }
        }
      `,
      fetchPolicy: 'no-cache',
    });
  }

  listen() {
    return this.apollo.use('yardlinkWs').subscribe({
      query: gql`
        subscription {
          live {
            imei
            coordinates
          }
        }
      `,
      fetchPolicy: 'no-cache',
    });
  }
}
