import { Injectable } from '@angular/core';
import { Domain } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';
import { Geofence } from '@ov-suite/models-yard';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeofenceService {
  geofenceChangeSubject = new Subject<unknown>();

  constructor(private readonly ovAutoService: OvAutoService, private readonly router: Router) {}

  prepareDomain(domainId: number): Promise<Domain> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .get({
          entity: Domain,
          id: domainId,
          keys: ['map'],
        })
        .then(domain => {
          resolve(domain);
        })
        .catch(err => reject(err.message));
    });
  }

  prepareGeofenceList(domainId: number): Promise<Geofence[]> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: Geofence,
          filter: { domainId: [domainId] },
        })
        .then(geofenceList => {
          resolve(geofenceList.data);
        })
        .catch(err => reject(err.message));
    });
  }

  prepareGeofenceShapes(geofenceId: number, geofenceList: Geofence[]): L.FeatureGroup {
    const drawnItems: L.FeatureGroup = L.featureGroup();

    geofenceList.forEach(item => {
      const geojson = JSON.parse(item.geojson);

      const shape = geojson['features'][0]?.geometry;

      switch (shape?.type) {
        case 'Polygon': {
          drawnItems.addLayer(this.prepareShape(item, geofenceId));
          break;
        }
        case 'LineString': {
          drawnItems.addLayer(this.prepareShape(item, geofenceId));
          break;
        }
        // TODO enable when circles have been fixed by leaflet - Cannot resize. 1
        // case 'Point': {
        //   drawnItems.addLayer(this.preparePointShape(item, geofenceId));
        //   break;
        // }
        default: {
          break;
        }
      }
    });

    return drawnItems;
  }

  prepareShape(geofence: Geofence, id: number) {
    const that = this;

    return L.geoJSON(JSON.parse(geofence.geojson), {
      onEachFeature(feature, layer) {
        layer.bindPopup(`
          <div><strong>Name:</strong> ${geofence.name}</div>
          <div><strong>Description:</strong> ${geofence.description}</div>
        `);

        if (geofence.id === id) {
          // @ts-ignore
          layer.setStyle({ color: 'red', fillColor: '#adadad', fillOpacity: 0.5, opacity: 1 });

          layer.on('click', e => {
            e.target.editing.enable();
          });

          layer.on('edit', e => {
            const temp: L.FeatureGroup = L.featureGroup();

            temp.addLayer(e.target);

            that.geofenceChangeSubject.next(temp.toGeoJSON());
          });
        }

        return layer;
      },
    });
  }

  // TODO enable when circles have been fixed by leaflet - Cannot resize. 2
  preparePointShape(geofence: Geofence, id: number) {
    const that = this;

    return L.geoJSON(JSON.parse(geofence.geojson), {
      pointToLayer: (feature, layer) => {
        if (feature.properties.radius) {
          return new L.Circle(layer, feature.properties.radius);
        }
      },
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
          <div><strong>Name:</strong> ${geofence.name}</div>
          <div><strong>Description:</strong> ${geofence.description}</div>
        `);

        if (geofence.id === id) {
          // @ts-ignore
          layer.setStyle({ color: 'red', fillColor: '#adadad', fillOpacity: 0.5, opacity: 1 });

          layer.on('click', e => {
            e.target.editing.enable();
          });

          // Only allows for moving the circle, cannot change radius of circle
          // Issue found at: https://github.com/Leaflet/Leaflet.draw/issues/945
          layer.on('edit', e => {
            const temp: L.FeatureGroup = L.featureGroup();

            const json = temp.addLayer(e.target).toGeoJSON();

            if (e.target instanceof L.Circle) {
              json['features'][0]['properties']['radius'] = e.target['_mRadius'];
            }

            that.geofenceChangeSubject.next(temp.toGeoJSON());

            // https://medium.com/geoman-blog/how-to-handle-circles-in-geojson-d04dcd6cb2e6
          });
        }

        return layer;
      },
    });
  }

  createGeofence(geofence: Geofence): Promise<Geofence> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .create({
          entity: Geofence,
          item: geofence,
        })
        .then(fence => resolve(fence))
        .catch(err => reject(err.message));
    });
  }

  async updateGeofence(geofence: Geofence): Promise<Geofence> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .update({
          entity: Geofence,
          item: geofence,
        })
        .then(fence => {
          resolve(fence);
        })
        .catch(err => reject(err.message));
    });
  }
}
