import { Routes } from '@angular/router';
import { GeofenceListComponent } from './list/geofence-list.component';
import { GeofenceManagementComponent } from '../geofence-management/geofence-management/geofence-management.component';

export const GeofenceRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: GeofenceListComponent,
      },
      {
        path: 'management',
        component: GeofenceManagementComponent,
      },
    ],
  },
];
