import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReportsServiceExitEntry } from './reports.service';
import { OvSuiteChart } from '../../modules/statistics/dashboard-v2/charts.constants';
import { DateRange } from '../../modules/statistics/dashboard-v2/dashboard-chart.constants';

/**
 * This service is used for managing the interactions between the user and
 * the database and requests another service [ReportsService] to provide the data, where this class
 * will format it submit an event to which the charts component can use easily.
 */

@Injectable({
  providedIn: 'root',
})
export class DashboardChartsService {
  fromDate: Date;

  toDate: Date;

  chartData = new Subject<OvSuiteChart>();

  chartDateRange = new Subject<DateRange>();

  // This service needs to take in a Chart Object
  constructor(private readonly dashboardChart: OvSuiteChart, private readonly reportsServiceExitEntry: ReportsServiceExitEntry) {
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.toDate = new Date();
    this.prepareChartData(this.fromDate, this.toDate);
  }

  /**
   * Updates the charts objects and updates the associated widgets.
   *
   * @param start
   * @param end
   */
  prepareChartData(start: Date, end: Date): void {
    const currentInterval = this.getPresetIntervals(this.getDatesDiff(start, end));

    this.reportsServiceExitEntry[this.dashboardChart.getServiceMethod()](start, end, currentInterval).subscribe(value => {
      // Update the chart object
      this.dashboardChart.update(value.data[this.dashboardChart.getServiceMethod()]);

      // Get the updated value
      const formatted = this.dashboardChart.getChart();

      // If chart has widgets, then go through and update the widget averages using the updated data
      if (this.dashboardChart.widgets) {
        this.dashboardChart.widgets.forEach(widget => {
          const avgItem = formatted.getData().datasets.find(set => set.label === widget.id);
          widget.updateAverage(this.calculateAverage(avgItem.data as number[]));
        });
      }

      this.chartData.next(formatted);

      // Update selected date ranges to allow for local and global picker to work in harmony
      this.chartDateRange.next({ fromDate: start, toDate: end });
    });
  }

  calculateAverage(set: number[]): string {
    return (set.reduce((a, n) => a + Number(n), 0) / set.length).toFixed(2);
  }

  // Sets intervals for the db query. This allows us to split the data into manageable pieces.
  getPresetIntervals(diff: number): string {
    if (diff === 1) {
      return '2 hour';
    }
    if (diff > 1 && diff <= 15) {
      return '1 day';
    }
    if (diff > 15 && diff <= 90) {
      return '1 week';
    }
    if (diff > 90 && diff <= 180) {
      return '2 week';
    }
    if (diff > 180 && diff <= 365) {
      return '1 month';
    }
    return '1 year';
  }

  getDatesDiff(start: Date, end: Date): number {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
    const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

    return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY));
  }

  getCurrentSelectedDates(): DateRange {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
  }

  // Setters that the chart component can manipulate
  resetDates(dates: DateRange) {
    this.fromDate = dates.fromDate;
    this.toDate = dates.toDate;
    this.prepareChartData(dates.fromDate, dates.toDate);
  }
}
