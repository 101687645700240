import { AfterContentInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { DashboardChartsService } from '../../../services/reports/dashboard-charts.service';

/**
 * Used for creating the actual chart and provides the interaction elements which the user has control over.
 */

interface DateRange {
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'ov-suite-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css'],
})
export class ChartsComponent implements AfterContentInit, OnDestroy {
  @Input() dashboardChart?: DashboardChartsService;

  chartTitle: string;

  selectedDateRange: string;

  chartData: Subscription;

  chartDateRange: Subscription;

  config: {
    labels: unknown[];
    data: unknown;
    options: unknown;
  };

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor() {
    this.chartTitle = 'Loading...';
    this.selectedDateRange = 'Loading...';

    this.config = {
      labels: [],
      data: {},
      options: {},
    };
  }

  ngAfterContentInit(): void {
    this.chartData = this.dashboardChart.chartData.subscribe(data => {
      this.config.labels = data.getLabels();
      this.config.data = data.getData();
      this.config.options = data.getOptions();

      this.chartTitle = data.getName();

      this.chart.chart.update();
    });

    this.chartDateRange = this.dashboardChart.chartDateRange.subscribe(data => {
      const from = moment(data.fromDate).format('DD MMM YY');
      const to = moment(data.toDate).format('DD MMM YY');

      this.selectedDateRange = `${from} - ${to}`;
    });
  }

  ngOnDestroy(): void {
    this.chartData.unsubscribe();
    this.chartDateRange.unsubscribe();
  }

  resetDateRange(event: DateRange): void {
    this.dashboardChart.resetDates(event);
  }
}
