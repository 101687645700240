import { Routes } from '@angular/router';
import { ExceptionsComponent } from './exceptions/exceptions.component';

export const ExceptionsRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ExceptionsComponent,
      },
    ],
  },
];
