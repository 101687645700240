import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import { Geofence } from '@ov-suite/models-yard';

@Component({
  selector: 'ov-suite-drawable-map',
  templateUrl: './drawable-map.component.html',
  styleUrls: ['./drawable-map.component.css'],
})
export class DrawableMapComponent implements OnInit {
  @Input() center: L.LatLng;

  @Input() geofenceList: Geofence[];

  @Input() selectedGeofence: Geofence;

  @Input() drawnItems: L.FeatureGroup;

  @Output() geoJsonEventEmitter = new EventEmitter<unknown>();

  map: L.Map;

  options: L.MapOptions;

  drawOptions: unknown;

  streetTileProvider = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.options = {
      layers: [
        L.tileLayer(this.streetTileProvider, {
          maxZoom: 21,
          attribution: '...',
          minZoom: 4,
        }),
      ],
      zoom: 16,
      center: this.center,
    };

    this.drawOptions = {
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
      },
      draw: {
        circlemarker: false,
        circle: false,
        marker: false,
        polyline: false,
        rectangle: {
          showArea: false,
        },
      },
    };
  }

  mapReady(map: L.Map): void {
    this.map = map;
  }

  onDrawCreated(e) {
    this.drawnItems.addLayer(e.layer);

    const temp: L.FeatureGroup = L.featureGroup();

    const json = temp.addLayer(e.layer).toGeoJSON();

    if (e.layer instanceof L.Circle) {
      json['features'][0]['properties']['radius'] = e.layer.getRadius();
    }

    this.geoJsonEventEmitter.emit(json);
  }
}
