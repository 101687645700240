import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TableTopTabMenuModule } from '../../components/table-top-tab-menu/table-top-tab-menu.module';
import { QueuesListComponent } from './queues-list/queues-list.component';
import { QueuesRoutes } from './queues.routing';
import { QueueConfigModalComponent } from './queue-config-modal/queue-config-modal.component';
import { QueuesService } from '../../services/queues/queues.service';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(QueuesRoutes), FormsModule, UiModule, TableTopTabMenuModule, NgbDropdownModule],
  declarations: [QueuesListComponent, QueueConfigModalComponent],
  providers: [QueuesService],
})
export class QueuesModule {}
