import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AutoService } from '@ov-suite/services';
import { ExitEntryEvent } from '@ov-suite/models-yard';

@Injectable({
  providedIn: 'root',
})
export class ReportsServiceExitEntry extends AutoService<ExitEntryEvent> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), ExitEntryEvent, 'ReportGenerator', 'ReportGenerators', 'yardlink');
  }

  getVehicleEntries(dateFrom: string, dateTo: string, interval: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getVehicleEntries($dateFrom: String!, $dateTo: String!, $interval: String!) {
          getVehicleEntries(dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval) {
            label
            count
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateTo,
        dateFrom,
        interval,
      },
    });
  }

  getTotalAverageTurnaroundTimes(dateFrom: string, dateTo: string, interval: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getTotalAverageTurnaroundTimes($dateFrom: String!, $dateTo: String!, $interval: String!) {
          getTotalAverageTurnaroundTimes(dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval) {
            label
            avgYardTurnaround
            avgTotalTurnaround
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateFrom,
        dateTo,
        interval,
      },
    });
  }

  getFenceTurnaround(dateFrom: string, dateTo: string, interval: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getFenceTurnaround($dateFrom: String!, $dateTo: String!, $interval: String!) {
          getFenceTurnaround(dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval) {
            label
            fenceTurnAround
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateFrom,
        dateTo,
        interval,
      },
    });
  }

  getInYardActivity(dateFrom: string, dateTo: string, interval: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getInYardActivity($dateFrom: String!, $dateTo: String!, $interval: String!) {
          getInYardActivity(dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval) {
            label
            arrivals
            entries
            exits
            inYard
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateFrom,
        dateTo,
        interval,
      },
    });
  }

  getExceptions(dateFrom: string, dateTo: string, interval: string) {
    return this.apollo.use('yardlink').subscribe({
      query: gql`
        query getExceptions($dateFrom: String!, $dateTo: String!, $interval: String!) {
          getExceptions(dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval) {
            label
            count
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        dateFrom,
        dateTo,
        interval,
      },
    });
  }
}
