<div class="geofence-alert-setup">
  <mat-card class="background-transparent shadow-none">
    <mat-card-title class="card-title">Configure Alert Trigger</mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onSubmit()" #yardViolationForm="ngForm">
        <div id="trigger-duration">
          <div class="form-group">
            <label>Duration <em>(minutes)</em></label>
            <input
              placeholder="Triggered After"
              id="triggerDuration"
              autocomplete="off"
              name="triggerDuration"
              type="number"
              class="form-control"
              [(ngModel)]="violationAlertTrigger.duration"
              required
            />
          </div>
        </div>

        <hr>

        <div id="user-search">
          <div class="form-group">
            <label>Search Users</label>
            <input
              placeholder="Search Users"
              id="userSearch"
              autocomplete="off"
              name="userSearch"
              type="text"
              class="form-control"
              #searchInput
              (keyup)="onSearchUser(searchInput.value)"
            />
          </div>
        </div>

        <hr>

        <div id="active-user-table">
          <label>Active Users</label>
          <table
            class="table table-sm table-bordered"
            [ngClass]="'table-striped'"
            aria-describedby="Link Table"
          >
            <thead>
            <th>User</th>
            <th>Activate</th>
            </thead>

            <tbody>
            <tr *ngFor="let activeUser of searchActiveViolationUsers;">
              <td>{{ activeUser.firstName }} {{ activeUser.lastName }}</td>
              <td>
                <button
                  mat-icon-button
                  type="button"
                  (click)="deactivateUser(activeUser)"
                >
                  <em class="fa fa-minus icon-danger"></em>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <hr>

        <div id="inactive-user-table">
          <label>Inactive Users</label>
          <table
            class="table table-sm table-bordered"
            [ngClass]="'table-striped'"
            aria-describedby="Link Table"
          >
            <thead>
            <th>User</th>
            <th>Activate</th>
            </thead>

            <tbody>
            <tr *ngFor="let inactiveUser of searchInactiveViolationUsers;">
              <td>{{ inactiveUser.firstName }} {{ inactiveUser.lastName }}</td>
              <td>
                <button
                  mat-icon-button
                  type="button"
                  (click)="activateUser(inactiveUser)"
                >
                  <em class="fa fa-plus icon-success"></em>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <mat-card-actions>
          <button
            mat-button
            color="warn"
            (click)="onCancelDialog()"
            type="button"
          >
            Cancel
          </button>
          <button
            mat-button
            color="primary"
            type="submit"
          >
            Save
          </button>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
</div>
