<div class="main-content">
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Vehicles On Site'"
      [formClass]="formClass"
      [service]="exitEntryEventService"
      [excludeColumns]="4"
      [hasBulkUpload]="false"
      [hideAddButton]="true"
      [overrideServiceMethod]="'getLiveFeedWithGeofence'"
      [hideColumnKeys]="hideColumnKeys"
      [showFiller]="false"
      [showTopBar]="true"
      [filterEnabled]="false"
      [showPageSelect]="false"
      [pageChangeEnabled]="false"
      [defaultOrderDirection]="'DESC'"
      [extraColumnEntity]="extraColumn"
      [enableRefresh]="true"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
