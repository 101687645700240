import { Component, OnInit } from '@angular/core';
import { ExceptionTypeModel } from 'libs/models-yard/src/lib/exception-type.model';
import { ExceptionLogModel } from '@ov-suite/models-yard';
import { OvAutoService } from '@ov-suite/services';
import { QueryParams } from '@ov-suite/helpers-shared';

interface TableTabs {
  title: string;
  path: string;
  key: number;
}

@Component({
  selector: 'ov-suite-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.css'],
})
export class ExceptionsComponent implements OnInit {
  constructor(public ovAutoService: OvAutoService) {}

  formClass = ExceptionLogModel;

  exceptionTypes: ExceptionTypeModel[];

  currentTab = 1;

  tabs: TableTabs[] = [];

  filter: Record<string, QueryParams[]>;

  hideColumnKeys = ['exceptionType'];

  get tableTitle() {
    const curTab = this.tabs.find(tab => tab.key === this.currentTab);
    return `${curTab.title} Exception Log`;
  }

  ngOnInit(): void {
    this.filter = {
      exceptionTypeId: [this.currentTab],
    };

    this.fetchExceptionTypes().then(res => {
      this.tabs = res.map(item => ({ title: item.name, path: '', key: item.id }));
    });
  }

  async fetchExceptionTypes(): Promise<ExceptionTypeModel[]> {
    const result = await this.ovAutoService.list({
      entity: ExceptionTypeModel,
      keys: ['id', 'name', 'description', 'active'],
    });
    return result.data;
  }

  onTabClick(id: string): void {
    const tabIndex = parseInt(id, 10);

    if (this.currentTab !== tabIndex) {
      this.currentTab = tabIndex;

      this.filter = {
        exceptionTypeId: [this.currentTab],
      };
    }
  }
}
