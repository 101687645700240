import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { QueueModel, QueueLimitModel } from '@ov-suite/models-yard';

@Component({
  templateUrl: './queue-config-modal.component.html',
  providers: [NgbModalConfig, NgbModal],
})
export class QueueConfigModalComponent implements OnInit {
  @Input() queue: QueueModel;

  @Input() dismissModal: NgbModalRef;

  priorityGroups: string[] = [];

  priorityNames: string[] = [];

  groupLimits: QueueLimitModel[] = [];

  priorityLimits: QueueLimitModel[] = [];

  comboLimits: QueueLimitModel[] = [];

  errorMessage: string;

  constructor(config: NgbModalConfig, public activeModal: NgbActiveModal, public readonly ovAutoService: OvAutoService) {
    // customize default values of modals used by this component tree
    config.backdrop = false;
    config.keyboard = false;
    config.size = 'lg';
    config.centered = true;
  }

  ngOnInit(): void {
    // this.getGroup();
    // this.getGroupQueues();
    const priorityGroups = new Set<string>();
    const priorityNames = new Set<string>();
    this.queue.priorityList.forEach(pl => {
      priorityGroups.add(pl.priorityGroup);
      priorityNames.add(pl.priority);
    });
    this.priorityGroups = Array.from(priorityGroups);
    this.priorityNames = Array.from(priorityNames);
    this.generateData();
  }

  generateData() {
    this.priorityGroups.forEach(group => {
      const exists = this.queue.limits.find(l => l.priority === null && l.priorityGroup?.toLowerCase() === group.toLowerCase());
      if (exists) {
        this.groupLimits.push(exists);
      } else {
        this.groupLimits.push(
          Object.assign(new QueueLimitModel(), {
            priorityGroup: group,
          }),
        );
      }
    });
    this.priorityNames.forEach(name => {
      const exists = this.queue.limits.find(l => l.priorityGroup === null && l.priority?.toLowerCase() === name.toLowerCase());
      if (exists) {
        this.priorityLimits.push(exists);
      } else {
        this.priorityLimits.push(
          Object.assign(new QueueLimitModel(), {
            priority: name,
          }),
        );
      }
    });
    this.queue.priorityList.forEach(pl => {
      const exists = this.queue.limits.find(
        l => l.priorityGroup?.toLowerCase() === pl.priorityGroup.toLowerCase() && l.priority?.toLowerCase() === pl.priority.toLowerCase(),
      );
      if (exists) {
        this.comboLimits.push(exists);
      } else {
        this.comboLimits.push(
          Object.assign(new QueueLimitModel(), {
            priority: pl.priority,
            priorityGroup: pl.priorityGroup,
          }),
        );
      }
    });
    const sortFunc = (a: QueueLimitModel, b: QueueLimitModel) => {
      if (a.priority < b.priority) {
        return 1;
      }
      if (a.priorityGroup < b.priorityGroup) {
        return 1;
      }
      if (a.priority > b.priority) {
        return -1;
      }
      if (a.priorityGroup > b.priorityGroup) {
        return -1;
      }
      return 0;
    };
    this.groupLimits.sort(sortFunc);
    this.priorityLimits.sort(sortFunc);
    this.comboLimits.sort(sortFunc);
  }

  onCancel() {
    this.dismissModal.close();
  }

  onSave() {
    const item = {
      id: this.queue.id,
      capacity: this.queue.capacity,
      limits: [],
    };

    item.limits = [
      ...this.groupLimits.filter(limit => !!limit.id || limit.limit >= -1),
      ...this.priorityLimits.filter(limit => !!limit.id || limit.limit >= -1),
      ...this.comboLimits.filter(limit => !!limit.id || limit.limit >= -1),
    ];

    this.ovAutoService
      .update({ entity: QueueModel, item })
      .then(() => {
        this.queue.limits = item.limits;
        this.dismissModal.close();
      })
      .catch(error => {
        this.errorMessage = error;
      });
  }

  clear(limit: QueueLimitModel) {
    limit.limit = null;
  }

  clearQueue() {
    this.queue.capacity = null;
  }
}
