import { Component, OnInit, ViewChild } from '@angular/core';
import { YardTurnaroundTimeViolationModel } from '@ov-suite/models-yard';
import { OvAutoService } from '@ov-suite/services';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import { UserRep } from '@ov-suite/models-idm';
import { HierarchyListComponent } from '@ov-suite/ui';
import { ViolationDialogResponse } from './yard-turnaround-time-violation-dialog/yard-turnaround-time-violation-dialog.component';
import { YardTurnaroundTimeViolationService } from '../../../services';

@Component({
  selector: 'ov-suite-yard-turnaround-time-violation',
  templateUrl: './yard-turnaround-time-violation.component.html',
  styleUrls: ['./yard-turnaround-time-violation.component.css'],
})
export class YardTurnaroundTimeViolationComponent implements OnInit {
  formClass = YardTurnaroundTimeViolationModel;

  /**
   * Controls the dialog.
   */
  dialogListener: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  selectedViolationAlertTrigger: YardTurnaroundTimeViolationModel;

  systemUsers: UserRep[];

  constructor(
    public readonly ovAutoService: OvAutoService,
    private readonly yardTurnaroundTimeViolationService: YardTurnaroundTimeViolationService,
  ) {}

  /**
   *  Purpose is for updating table after crud operations
   */
  @ViewChild('hierarchyList') hierarchyTable: HierarchyListComponent<YardTurnaroundTimeViolationModel>;

  async ngOnInit() {
    this.systemUsers = await this.yardTurnaroundTimeViolationService.prepareSystemUsers();
  }

  onViolationEventEmitted(e: false | ViolationDialogResponse): void {
    if (e) this.prepareSaveDialogResponse(e);

    this.dialogListener.next(false);
  }

  async prepareSaveDialogResponse(e: ViolationDialogResponse): Promise<void> {
    const { violation, activeUsers, inactiveUsers } = e;

    let dialogResponseViolation: YardTurnaroundTimeViolationModel;

    try {
      // First need to save the violation
      if (violation) {
        // This could be false and that means we aren't making a change
        if (violation.id) {
          dialogResponseViolation = await this.yardTurnaroundTimeViolationService.updateYardViolation(violation);
        } else {
          dialogResponseViolation = await this.yardTurnaroundTimeViolationService.createYardViolation(violation);
        }
      }

      if (activeUsers) {
        // Save this list of users against this violation.
        await this.yardTurnaroundTimeViolationService.activateViolationUsers(activeUsers, dialogResponseViolation);
      }

      if (inactiveUsers) {
        // Deactivate users against this violation.
        this.yardTurnaroundTimeViolationService.deactivateViolationUsers(inactiveUsers);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      this.hierarchyTable.refreshData(true);
    }
  }

  onEditViolation(editViolation: YardTurnaroundTimeViolationModel): void {
    this.selectedViolationAlertTrigger = _.clone(editViolation);

    this.openDialog();
  }

  onAddViolation(): void {
    const item = new YardTurnaroundTimeViolationModel();
    item.duration = null;
    item.domainId = parseInt(sessionStorage.getItem('selectedDomain'), 10);
    item.active = true;

    this.selectedViolationAlertTrigger = _.clone(item);

    this.openDialog();
  }

  openDialog(): void {
    this.dialogListener.next(true);
  }

  async onDeleteViolation(violationId: YardTurnaroundTimeViolationModel): Promise<void> {
    await this.yardTurnaroundTimeViolationService.onViolationDeactivated(violationId.id);
  }
}
