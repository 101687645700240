import { Injectable } from '@angular/core';
import { YardTurnaroundTimeViolationModel, YardTurnaroundTimeViolationUserModel } from '@ov-suite/models-yard';
import { OvAutoService, OvAutoServiceMultipleMutationParams } from '@ov-suite/services';
import { UserRep } from '@ov-suite/models-idm';
import { YardTurnaroundTimeViolationUsersService } from './yard-turnaround-time-violation-users.service';

@Injectable({
  providedIn: 'root',
})
export class YardTurnaroundTimeViolationService {
  constructor(
    public readonly ovAutoService: OvAutoService,
    private readonly yardTurnaroundTimeViolationUsersService: YardTurnaroundTimeViolationUsersService,
  ) {}

  createYardViolation(newItem: YardTurnaroundTimeViolationModel): Promise<YardTurnaroundTimeViolationModel> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .create({
          entity: YardTurnaroundTimeViolationModel,
          item: newItem,
        })
        .then(savedItem => {
          resolve(savedItem);
        })
        .catch(e => reject(e.message));
    });
  }

  updateYardViolation(updateItem: YardTurnaroundTimeViolationModel): Promise<YardTurnaroundTimeViolationModel> {
    return this.ovAutoService.update({
      entity: YardTurnaroundTimeViolationModel,
      item: updateItem,
    });
  }

  async activateViolationUsers(users: UserRep[], violation: YardTurnaroundTimeViolationModel) {
    const yardViolationUsers: OvAutoServiceMultipleMutationParams = {};

    users.forEach((user, index) => {
      const yardViolationUser = new YardTurnaroundTimeViolationUserModel();

      yardViolationUser.violationId = violation.id;
      yardViolationUser.userId = user.id;
      yardViolationUser.active = true;

      yardViolationUsers[`turnAroundTimeViolationUser${index}`] = {
        type: 'create',
        entity: YardTurnaroundTimeViolationUserModel,
        item: yardViolationUser,
      };
    });

    await this.ovAutoService.multipleMutation(yardViolationUsers);
  }

  async deactivateViolationUsers(violationUserList: YardTurnaroundTimeViolationUserModel[]) {
    const yardViolationUsers: OvAutoServiceMultipleMutationParams = {};

    violationUserList.forEach(violationUser => {
      violationUser.active = false;

      yardViolationUsers[`turnAroundTimeViolationUser${violationUser.id}`] = {
        type: 'update',
        entity: YardTurnaroundTimeViolationUserModel,
        item: violationUser,
      };
    });

    await this.ovAutoService.multipleMutation(yardViolationUsers);
  }

  /**
   * Retrieves all users in the system
   */
  async prepareSystemUsers(): Promise<UserRep[]> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: UserRep,
          keys: [ 'id', 'firstName', 'lastName', 'domains' ],
          orderColumn: 'firstName',
          orderDirection: 'ASC',
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => reject(err.message));
    });
  }

  /**
   * Once the violation is deleted, we need to deactivate the users associated with this violation
   * @param violationId
   */
  async onViolationDeactivated(violationId: number) {
    const users = await this.yardTurnaroundTimeViolationUsersService.prepareActivatedUsers(violationId);

    await this.deactivateViolationUsers(users);
  }
}
