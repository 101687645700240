interface FormKey {
  key: string;
}

const getRouterLink = (item: FormKey, url: string) => {
  return [url.slice(1).split('?')[0], 'edit'];
};

const getQueryParams = (item: FormKey, url: string) => {
  return { key: item.key };
};

export { getQueryParams, getRouterLink };
