import { TrackerInformation } from '@ov-suite/models-yard';
import { AutoService, OvAutoServiceListParams } from '@ov-suite/services';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { mapToClass, PageReturn } from '@ov-suite/ov-metadata';

@Injectable({
  providedIn: 'root',
})
export class TrackerInformationService extends AutoService<TrackerInformation> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('yardlink'), TrackerInformation, 'TrackerInformation', 'TrackerInformation', 'yardlink');
  }

  listTrackerInformation(params: Omit<OvAutoServiceListParams<TrackerInformation>, 'entity'>): Promise<PageReturn<TrackerInformation>> {
    const { offset = 0, limit = 100, query, search, filter, orderColumn = 'id', orderDirection = 'DESC' } = params;

    const searchKeysToIgnore = ['timestamp', 'battery', 'used', 'avgBattery'];

    const searchKeys = {};
    Object.keys(search)
      .filter(key => !searchKeysToIgnore.includes(key))
      .forEach(key => {
        searchKeys[key] = search[key];
      });

    return new Promise((resolve, reject) => {
      this.apollo
        .use('yardlink')
        .query({
          query: gql`
            query listTrackerInformation($params: ListParamsInput!) {
              listTrackerInformation(params: $params) {
                data {
                  id
                  imei
                  timestamp
                  battery
                  avgBattery
                  used
                }
                totalCount
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search: searchKeys,
              filter,
              query,
            },
          },
        })
        .subscribe(res => this.mapPageReturn(res, resolve, 'listTrackerInformation'), reject);
    });
  }

  mapPageReturn(response, resolve, name) {
    const rawData = response.data[name];

    const { totalCount } = rawData;
    const data = rawData.data.map(item => {
      return mapToClass(TrackerInformation, item);
    });

    resolve({ data, totalCount });
  }
}
