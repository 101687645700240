import { Routes } from '@angular/router';
import { TrackersComponent } from './trackers/trackers.component';

export const TrackingRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: TrackersComponent,
      },
    ],
  },
];
