<span [ngClass]="[pickerClass, 'presets-picker']">
  <button mat-mini-fab class="charts-preset" (click)="presetDates('1D')">1D</button>
  <button mat-mini-fab class="charts-preset" (click)="presetDates('7D')">1W</button>
  <button mat-mini-fab class="charts-preset" (click)="presetDates('1M')">1M</button>
  <button mat-mini-fab class="charts-preset" (click)="presetDates('3M')">3M</button>
  <button mat-mini-fab class="charts-preset" (click)="presetDates('1Y')">1Y</button>
</span>
<span [ngClass]="[pickerClass, 'calendar-picker']">
  <button
    mat-button
    mat-raised-button
    color="primary"
    class="charts-preset-calendar"
    (click)="openDateRangePicker()"
  >
  Date Range
</button>
</span>
