import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { LiveFeedListComponent } from './list/live-feed-list.component';
import { LiveFeedRoutes } from './live-feed-routing';
import { ExitEntryEventService } from '../../services/exit-entry-event/exit-entry-event.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(LiveFeedRoutes),
    FormsModule,
    UiModule,
  ],
  declarations: [LiveFeedListComponent],
  providers: [ExitEntryEventService],
})
export class LiveFeedModule { }
