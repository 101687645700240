import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableTopTabMenuItem } from './table-top-tab-menu.model';

@Component({
  selector: 'ov-suite-table-top-tab-menu',
  templateUrl: './table-top-tab-menu.component.html',
  styleUrls: ['./table-top-tab-menu.component.scss'],
})
export class TableTopTabMenuComponent implements OnInit {
  @Input() active = '';

  @Input() tabs: TableTopTabMenuItem[] = [];

  @Input() title = '';

  @Input() backButtonLink: string | string[];

  @Output() tabClick = new EventEmitter<string>();

  ngOnInit() {}

  onTabClick(key) {
    this.active = key;
    this.tabClick.emit(key);
  }

  onDownload() {}
}
