import { Component, OnInit } from '@angular/core';
import { ReportsServiceExitEntry } from '../../../services/reports/reports.service';
import { DashboardChartsService } from '../../../services/reports/dashboard-charts.service';

import {
  TotalArrivals,
  TotalTurnAroundTimes,
  InYardActivity,
  FenceTurnAround,
  Exceptions,
  DateRange,
  EntriesWidget,
  YardTurnAroundWidget,
  TotalTurnAroundWidget,
  FenceTurnAroundWidget,
} from './dashboard-chart.constants';
import { DashboardWidget } from './charts.constants';

/**
 * Sole purpose is to create the charts and organize them on the front end.
 */

@Component({
  selector: 'ov-suite-dashboard-v2',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ReportsServiceExitEntry],
})
export class DashboardComponent implements OnInit {
  dashboardCharts: DashboardChartsService[] = [];

  dashboardWidgets: DashboardWidget[] = [];

  chartsColspan: number;

  chartsRowspan: number;

  chartsRowHeight: string;

  widgetsColspan: number;

  widgetsRowspan: number;

  widgetsRowHeight: string;

  /**
   * For applying to all charts,
   * and maintains its own date range.
   */
  dateRange: DateRange = {
    fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    toDate: new Date(),
  };

  constructor(private readonly reportsServiceExitEntry: ReportsServiceExitEntry) {
    this.chartsColspan = 6;
    this.chartsRowspan = 4;
    this.chartsRowHeight = '6.2em';

    this.widgetsColspan = 3;
    this.widgetsRowspan = 2;
    this.widgetsRowHeight = '5em';
  }

  ngOnInit() {
    this.resizeWindow(window.innerWidth);
    this.prepareCharts();
  }

  onResize(event: UIEvent): void {
    this.resizeWindow((event.target as Window).innerWidth);
  }

  /**
   * Responsiveness of the page
   * @param size
   */
  resizeWindow(size: number): void {
    if (size <= 480) {
      this.chartsColspan = 12;
      this.chartsRowspan = 8;
      this.chartsRowHeight = '2em';

      this.widgetsColspan = 12;
      this.widgetsRowspan = 12;
      this.widgetsRowHeight = '0.75em';
    } else if (size > 480 && size <= 768) {
      this.chartsColspan = 12;
      this.chartsRowspan = 10;
      this.chartsRowHeight = '3.25em';

      this.widgetsColspan = 6;
      this.widgetsRowspan = 5;
      this.widgetsRowHeight = '2em';
    } else if (size > 768 && size <= 1024) {
      this.chartsColspan = 6;
      this.chartsRowspan = 5;
      this.chartsRowHeight = '5em';

      this.widgetsColspan = 6;
      this.widgetsRowspan = 3;
      this.widgetsRowHeight = '2.5em';
    } else if (size > 1024 && size <= 1201) {
      this.chartsColspan = 6;
      this.chartsRowspan = 4;
      this.chartsRowHeight = '6.2em';

      this.widgetsColspan = 3;
      this.widgetsRowspan = 2;
      this.widgetsRowHeight = '5em';
    } else if (size > 1201 && size <= 1440) {
      this.chartsColspan = 6;
      this.chartsRowspan = 4;
      this.chartsRowHeight = '7.5em';

      this.widgetsColspan = 3;
      this.widgetsRowspan = 2;
      this.widgetsRowHeight = '5em';
    } else if (size > 1440 && size <= 2160) {
      this.chartsColspan = 4;
      this.chartsRowspan = 3;
      this.chartsRowHeight = '10em';

      this.widgetsColspan = 3;
      this.widgetsRowspan = 2;
      this.widgetsRowHeight = '6em';
    } else if (2160 > 1440) {
      this.chartsColspan = 4;
      this.chartsRowspan = 3;
      this.chartsRowHeight = '12em';

      this.widgetsColspan = 3;
      this.widgetsRowspan = 1;
      this.widgetsRowHeight = '7em';
    }
  }

  /**
   *
   * Store all chart Objects in an array.
   *
   * It's done here, so we can display the data in the associated html as well as pass
   * dynamic objects to the individual chart components while still providing global
   * functionality to the page.
   *
   * Chart options are stored in the dashboard-chart.constants.ts
   *
   * Objects are in created in charts.constants.ts
   */
  prepareCharts(): void {
    const vehicleArrivalsChart = new DashboardChartsService(TotalArrivals, this.reportsServiceExitEntry);

    const vehicleTurnAroundChart = new DashboardChartsService(TotalTurnAroundTimes, this.reportsServiceExitEntry);

    const yardActivityChart = new DashboardChartsService(InYardActivity, this.reportsServiceExitEntry);

    const fenceTurnAroundChart = new DashboardChartsService(FenceTurnAround, this.reportsServiceExitEntry);

    const exceptionsChart = new DashboardChartsService(Exceptions, this.reportsServiceExitEntry);

    this.dashboardCharts = [vehicleArrivalsChart, vehicleTurnAroundChart, yardActivityChart, fenceTurnAroundChart, exceptionsChart];

    this.dashboardWidgets = [EntriesWidget, YardTurnAroundWidget, TotalTurnAroundWidget, FenceTurnAroundWidget];
  }

  /**
   * Used to mass update charts only. Maintains a date range of its own
   *
   * @param dates
   */
  massUpdate(dates: DateRange): void {
    this.dateRange = dates;
    this.dashboardCharts.forEach(dashboard => dashboard.resetDates(this.dateRange));
  }
}
