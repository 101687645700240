import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { LiveFeedExtraColumnsModel } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: LiveFeedExtraColumnsModel,
      api: 'yardlink',
      createTitle: 'Add Live Feed Table Extra Columns Configuration',
      route: ['live-feed-extra-columns'],
      tableTitle: 'Live Feed Table Extra Columns Configuration',
      hideImport: true,
      showFiller: true,
    }),
  ],
})
export class LiveFeedExtraColumnsModule {}
