import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiModule } from '@ov-suite/ui';
import { CommonModule } from '@angular/common';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { ExceptionsRouting } from './exceptions.routing';
import { TableTopTabMenuModule } from '../../components/table-top-tab-menu/table-top-tab-menu.module';

@NgModule({
  declarations: [ExceptionsComponent],
  imports: [CommonModule, RouterModule.forChild(ExceptionsRouting), TableTopTabMenuModule, UiModule],
})
export class ExceptionsModule {}
