import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { AccessCode } from '@ov-suite/models-yard';
import { AccessCodeValidatorService } from './access-code-validator.service';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: AccessCode,
      api: 'yardlink',
      createTitle: 'Access Code',
      route: ['access-code'],
      tableTitle: 'Access Codes',
      showFiller: false,
      permissionId: 602,
    }),
  ],
  providers: [AccessCodeValidatorService],
})
export class AccessCodeModule {}
