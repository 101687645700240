import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Domain } from '@ov-suite/models-admin';
import { Geofence } from '@ov-suite/models-yard';
import { SessionStorage } from '@ov-suite/helpers-angular';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { GeofenceService } from '../../../services/geofence/geofence.service';

@Component({
  selector: 'ov-suite-geofence-management',
  templateUrl: './geofence-management.component.html',
  styleUrls: ['./geofence-management.component.css'],
})
export class GeofenceManagementComponent implements OnInit, OnDestroy {
  domainId: number;

  domain: Domain;

  mapCenter: L.LatLng;

  geofenceList: Geofence[];

  geofenceId = 0;

  selectedGeofence: Geofence;

  shapes: L.FeatureGroup = L.featureGroup();

  /** For responsive columns grid layout */
  screenWidth: number;

  screenHeight: number;

  ready = false;

  routeSubscription: Subscription;

  editGeofenceGeoJsonSubscription: Subscription;

  validation = {
    name: true,
    description: true,
    geojson: true,
  };

  constructor(private readonly route: ActivatedRoute, private readonly router: Router, public geofenceService: GeofenceService) {}

  async ngOnInit() {
    this.screenHeight = window.innerHeight;

    this.screenWidth = window.innerWidth;

    this.routeSubscription = this.route.queryParams.subscribe(async params => {
      this.geofenceId = parseInt(params.id, 10);

      this.domainId = Number(SessionStorage.getSelectedDomain());

      this.geofenceList = await this.geofenceService.prepareGeofenceList(this.domainId);

      this.shapes = this.geofenceService.prepareGeofenceShapes(this.geofenceId, this.geofenceList);

      this.domain = await this.geofenceService.prepareDomain(this.domainId);

      this.mapCenter = new L.LatLng(this.domain.map.latitude, this.domain.map.longitude);

      this.selectedGeofence = this.prepareSelectedGeofence();

      this.ready = true;
    });

    /**
     * Procedure for editing geofence geojson is nested.
     *
     * Edit function is bound to the geofence when it is created.
     */
    this.editGeofenceGeoJsonSubscription = this.geofenceService.geofenceChangeSubject.subscribe(geoJson => {
      this.addGeofence(geoJson);
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();

    this.editGeofenceGeoJsonSubscription.unsubscribe();
  }

  prepareSelectedGeofence(): Geofence {
    if (this.geofenceId) {
      return this.geofenceList.find(geofence => geofence.id === this.geofenceId);
    }

    return this.prepareNewGeofence();
  }

  prepareNewGeofence(): Geofence {
    const geofence = new Geofence();

    geofence.name = '';
    geofence.description = '';
    geofence.geojson = '';

    return geofence;
  }

  /**
   * Editing: GeofenceService
   * Adding: DrawableMap
   * @param e
   */
  addGeofence(e) {
    this.selectedGeofence.geojson = JSON.stringify(e);
  }

  saveGeofence() {
    if (this.validateGeofence()) {
      if (this.geofenceId === 0) {
        this.geofenceService
          .createGeofence(this.selectedGeofence)
          .then(fence => {
            this.router.navigate(['geofence/management'], { queryParams: { id: fence.id } });
          })
          .then(() => {
            swal.fire({
              title: 'Geofence Created',
              text: 'Successfully created geofence.',
              type: 'success',
              confirmButtonText: 'Okay',
            });
          })
          .catch(err => {
            swal.fire({
              title: 'Error Creating Geofence',
              text: err.message,
              type: 'error',
              confirmButtonText: 'Okay',
            });
          });
      } else {
        this.geofenceService
          .updateGeofence(this.selectedGeofence)
          .then(() => {
            swal.fire({
              title: 'Geofence Updated',
              text: 'Successfully updated geofence.',
              type: 'success',
              confirmButtonText: 'Okay',
            });
          })
          .catch(err => {
            swal.fire({
              title: 'Error Creating Geofence',
              text: err.message,
              type: 'error',
              confirmButtonText: 'Okay',
            });
          });
      }
    }
  }

  validateGeofence(): boolean {
    this.validation.name = !!this.selectedGeofence.name;

    this.validation.description = !!this.selectedGeofence.description;

    this.validation.geojson = !!this.selectedGeofence.geojson;

    if (!this.validation.geojson) {
      swal.fire({
        title: 'Invalid Geofence',
        text: 'Please draw a geofence before attempting to save.',
        type: 'error',
        confirmButtonText: 'Okay',
      });
    }

    return this.validation.name && this.validation.description && this.validation.geojson;
  }

  /**
   * Loads entire geofence into view, including alerts.
   * @param e
   */
  async onNavigateGeofence(e) {
    await this.router.navigate(['geofence/management'], { queryParams: { id: e } });
  }
}
