<mat-card class="background-white">
  <mat-card-title>
    <div class="trigger-actions">
      <div class="ht-header">
        <span class="card-title">Alert Triggers</span>
      </div>
      <div>
        <button
          mat-button
          color="accent"
          class="mat-icon-small"
          (click)="createAlert()"
        >
          <ov-suite-icons
            [icon]="'add'"
            [size]="'icon-md'"
          >
          </ov-suite-icons>
          <span>Add Alert</span>
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="geofence-alert-table">
      <table
        class="table table-sm"
        [ngClass]="'table-striped'"
        aria-describedby="Link Table"
      >
        <thead>
        <th>Alert Duration <em>(After Minutes)</em></th>
        <th>Actions</th>
        </thead>

        <tbody>
          <tr *ngFor="let alert of geofenceAlertList">
            <td>
              {{ alert.duration }}
            </td>
            <td>
              <button
                type="button"
                class="btn btn-sm m-1 table-button"
                (click)="editAlert(alert)"
              >
                <em class="fa fa-pencil"></em>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger m-1 table-button"
                (click)="deleteAlert(alert)"
              >
                <em class="fa fa-trash"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<ov-suite-mat-dialog
  [header]="false"
  [actions]="false"
  [width]="550"
  [content]="geofenceAlertsDialogContent"
  [toggler]="dialogListener"
></ov-suite-mat-dialog>

<ng-template #geofenceAlertsDialogContent>
  <ov-suite-geofence-alert-management-dialog
    [alert]="selectedAlert"
    [activeUsers]="selectedAlertActiveUsers"
    [allUsers]="userList"
    (alertEventEmitter)="onAlertManagementDialogEventEmitter($event)"
  ></ov-suite-geofence-alert-management-dialog>
</ng-template>
