import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DateRangePickerComponentNew } from './date-range-picker/date-range-picker.component';

interface DateRange {
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'ov-suite-dynamic-date-picker',
  templateUrl: './dynamic-date-picker.component.html',
  styleUrls: ['./dynamic-date-picker.component.css'],
})
export class DynamicDatePickerComponentNew implements OnInit {
  @ViewChild('picker') datePicker;

  datePickerOpened = false;

  @Input() dateRange: DateRange;

  @Input() pickerClass = '';

  @Output() dateRangeEmitter = new EventEmitter<DateRange>();

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDateRangePicker(): void {
    if (!this.datePickerOpened) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.hasBackdrop = true;

      dialogConfig.data = this.dateRange;

      dialogConfig.panelClass = 'mat-dialog-container-white';

      dialogConfig.width = '600px';

      const dialogRef = this.dialog.open(DateRangePickerComponentNew, dialogConfig);

      this.datePickerOpened = true;

      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.dateRangeEmitter.emit(data);
        }
        this.datePickerOpened = false;
      });
    }
  }

  presetDates(period: string): void {
    const range = {
      fromDate: null,
      toDate: new Date(),
    };

    switch (period) {
      case '1D':
        range.fromDate = new Date(new Date().setDate(new Date().getDate() - 1));
        this.dateRangeEmitter.emit(range);
        break;
      case '7D':
        range.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
        this.dateRangeEmitter.emit(range);
        break;
      case '1M':
        range.fromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
        this.dateRangeEmitter.emit(range);
        break;
      case '3M':
        range.fromDate = new Date(new Date().setMonth(new Date().getMonth() - 3));
        this.dateRangeEmitter.emit(range);
        break;
      case '1Y':
        range.fromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        this.dateRangeEmitter.emit(range);
        break;
    }
  }
}
