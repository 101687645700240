import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { MobileListConfig } from '@ov-suite/models-shared';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: MobileListConfig,
      api: 'shared',
      createTitle: 'Mobile List Configuration',
      route: ['mobile-list-config'],
      tableTitle: 'Mobile List Configurations'
    })
  ]
})
export class MobileListConfigModule {}
