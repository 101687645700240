import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@ov-suite/helpers-angular';
import { YardWorkflowProcess } from '@ov-suite/models-yard';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { startCase } from 'lodash';
import { YardWorkflowProcessService } from '../../../services/yard-workflow-process/yard-workflow-process.service';

@Component({
  selector: 'ov-suite-process-view',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.scss'],
})
export class ProcessViewComponent implements OnInit {
  @ViewChild('report', { static: false }) content: ElementRef;

  id: string;

  public data: YardWorkflowProcess;

  public preview: boolean;

  public variables: unknown[];

  apiUrl: string;

  constructor(protected route: ActivatedRoute, protected router: Router, protected workflowProcessService: YardWorkflowProcessService) {
    this.apiUrl = environment.values.apiUrl.yard;
    this.route.params.subscribe(param => {
      this.id = param['id'];
    });
  }

  ngOnInit() {
    this.workflowProcessService.getById(this.id, true).subscribe(response => {
      this.data = response.data['getProcessInstance'] as YardWorkflowProcess;

      const flowableVars = this.data['variables'] || [];
      const arr = flowableVars.map((i: Record<string, string | boolean | number | Record<string, string | number>[]>) => {
        const val = { ...i };
        val.name = this.formatVariable(i.name);

        if (val.value === 'true') {
          val.value = 'Yes';
        } else if (val.value === 'false') {
          val.value = 'No';
          // eslint-disable-next-line no-restricted-globals
        } else if (this.isNumeric(val.value)) {
          val.isNumber = true;
        } else if (this.isBase64(val.value)) {
          val.isBase64 = true;
        } else if (this.isJson(val.value) && val.value) {
          if (typeof val.value === 'string' && Array.isArray(JSON.parse(val.value))) {
            val.value = JSON.parse(val.value);
            val.isArray = true;
          } else {
            val.value = this.jsonToArray(val.value);
            val.isArray = true;
          }
        }
        return val;
      });
      this.variables = arr;
    });
  }

  onDelete(id: string) {
    const agree = confirm('Are you sure you want to delete this process?');
    if (agree) {
      this.workflowProcessService.deleteProcess(id).subscribe(() => {
        this.router.navigate(['workflow-processes']);
      });
    }
  }

  isBase64(str) {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(str) && str.length > 300;
  }

  formatVariable(text) {
    if (text[0] === text[0].toUpperCase()) {
      return text;
    }

    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  isJson(str) {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(str)) {
      return false;
    }

    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  jsonToArray(obj): Record<string, string | number>[] {
    obj = JSON.parse(obj);
    const arr: Record<string, string | number>[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const x in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(x)) {
        if (obj[x]) {
          arr.push({
            name: this.formatVariable(x),
            value: obj[x],
          });
        }
      }
    }
    return arr;
  }

  formatDate(date) {
    return moment(date).format('DD/MM/YY hh:mm:ss A');
  }

  duration(start, end) {
    moment.relativeTimeThreshold('ss', 60);
    moment.updateLocale('en', {
      relativeTime: {
        s(number, withoutSuffix, key, isFuture) {
          return `${number} seconds`;
        },
      },
    });

    return moment.duration(moment(end).diff(moment(start))).humanize(false);
  }

  isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  previewImage() {
    this.preview = !this.preview;
    return this.preview;
  }

  download() {
    // Get the element to export into pdf
    // const pdfContent = window.document.getElementById('report-container');
    const pdfContent = this.content.nativeElement;

    // Use html2canvas to apply CSS settings
    html2canvas(pdfContent).then(canvas => {
      // const img = canvas.toDataURL('image/png');
      // const doc = new jsPDF();
      // // @ts-ignore
      // doc.addImage(img, 'JPEG', 20, 20);
      // doc.save('test.pdf');

      const { width } = canvas;
      const { height } = canvas;
      const millimeters = {
        width: Math.floor(width * 0.264583),
        height: Math.floor(height * 0.264583),
      };

      const imgData = canvas.toDataURL('image/png');
      const doc = new jsPDF('p', 'mm', 'a4');
      doc.deletePage(1);
      doc.addPage([millimeters.width, millimeters.height], 'portrait');
      // @ts-ignore
      doc.addImage(imgData, 'PNG', 0, 0);
      doc.save('WebSiteScreen.pdf');
    });
    // const DATA = this.couponPage.nativeElement;
    // const doc: jsPDF = new jsPDF("p", "mm", "a4");
    // var width = doc.internal.pageSize.getWidth();
    // var height = doc.internal.pageSize.getHeight();
    //
    // const margins = {
    //   top: 10,
    //   bottom: 10,
    //   left: 10,
    //   width: 600
    // };
    // doc.setProperties({
    //   title: 'test',
    //   subject: 'subject',
    //   author: 'admin.plenty.rdthree.com',
    //   creator: '© LinkSuite 2020'
    // });
    // // $(printWindow.document.body).css("width", "1500px");
    // // pdf.addHTML(printWindow.document.body,10,10,{pagesplit:true},function() {
    // //   pdf.save("report_"+moment().format("MM-DD-YYYY h:mma")+'.pdf');
    // // }, margins);
    //
    // doc.html(pdfContent, {
    //   callback: (doc) => {
    //     doc.output("dataurlnewwindow");
    //   }
    // });
  }

  getJsonKeys(jsonObject: unknown): string[] {
    try {
      if (typeof jsonObject === 'object') {
        return Object.keys(jsonObject);
      }

      if (typeof jsonObject === 'string') {
        const obj = JSON.parse(jsonObject);
        return Object.keys(obj);
      }

      return [];
    } catch (e) {
      return [];
    }
  }

  getStartCase(input: string): string {
    return startCase(input);
  }

  getObjectType(input: string | string[]): string {
    if (typeof input === 'string') {
      return input;
    }

    if (input.length > 0) {
      return input.reduce((accumulator, current) => {
        let res = '';
        Object.keys(current).forEach(key => {
          res += `${this.getStartCase(key)}: ${current[ key ]}; `;
        });
        return res;
      }, '');
    }

    let result = '';
    Object.keys(input).forEach(key => {
      result += `${this.getStartCase(key)}: ${input[key]}; `;
    });

    return result;
  }
}
