import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from '@ov-suite/ui';
import { TrackersComponent } from './trackers/trackers.component';
import { TrackingRoutes } from './trackers.routing';

@NgModule({
  declarations: [TrackersComponent],
  imports: [CommonModule, RouterModule.forChild(TrackingRoutes), UiModule],
})
export class TrackersModule {}
