import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { GeofenceAlert } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: GeofenceAlert,
      api: 'yardlink',
      createTitle: 'Geofence Alert',
      route: ['alert'],
      tableTitle: 'Geofence Alerts',
      showFiller: false,
    }),
  ],
  providers: [],
})
export class GeofenceAlertModule {}
