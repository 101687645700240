<ov-suite-hierarchy-table
  [title]="'Battery Information'"
  [formClass]="formClass"
  [ovAutoService]="ovAutoService"
  [service]="trackerInformationService"
  [excludeColumns]="4"
  [hasBulkUpload]="false"
  [hideAddButton]="true"
  [showFiller]="false"
  [showTopBar]="true"
  [filterEnabled]="false"
  [showPageSelect]="false"
  [defaultOrderDirection]="'DESC'"
  [overrideServiceMethod]="'listTrackerInformation'"
  [enableRefresh]="true"
>
</ov-suite-hierarchy-table>
