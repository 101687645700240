import { Component } from '@angular/core';
import { ExitEntryEvent, LiveFeedExtraColumnsModel } from '@ov-suite/models-yard';
import { HierarchyTab } from '@ov-suite/ui';
import { environment } from '@ov-suite/helpers-angular';
import { getFieldMetadata } from '@ov-suite/ov-metadata';
import { ExitEntryEventService } from '../../../services/exit-entry-event/exit-entry-event.service';

@Component({
  selector: 'ov-suite-historical-list',
  templateUrl: './live-feed-list.component.html',
  styleUrls: ['./live-feed-list.component.scss'],
})
export class LiveFeedListComponent {
  // Class - Required
  formClass = ExitEntryEvent;

  envCols = environment.values.other.tableColumnVisibilityToggle?.yardLink?.hideKeys
    ? environment.values.other.tableColumnVisibilityToggle?.yardLink?.hideKeys
    : [];

  hideColumnKeys = ['exitUser', 'exitTime', 'duration', 'entryUser', 'actionsBasic', 'reason', ...this.envCols];

  tabs: HierarchyTab[] = [
    { title: 'Vehicles On Site / In Yard', path: '/live-feed' },
    // { title: 'Entered & Departed Collections', path: '/entered-departed-collections' },
  ];

  extraColumn = getFieldMetadata(LiveFeedExtraColumnsModel).name;

  constructor(public exitEntryEventService: ExitEntryEventService) {}
}
