<div class="map-container">
  <div class="map-frame">
    <div
      id="map"
      leaflet
      (leafletMapReady)="mapReady($event)"
      [leafletOptions]="options"
      leafletDraw
      [leafletDrawOptions]="drawOptions"
      (leafletDrawCreated)="onDrawCreated($event)"
    >
      <div [leafletLayer]="drawnItems"></div>
    </div>
  </div>
</div>
