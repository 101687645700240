import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AreaRoutes } from './form-builder.routing';
import { FormBuilderListComponent } from './list/form-builder-list.component';
import { UiModule } from '@ov-suite/ui';
import { FormBuilderAddOrEditComponent } from './add-or-edit/form-builder-add-or-edit.component';
import { FormService } from './form.service';
import { FormBuilderComponent } from './formio/formbuilder.component';
import { CustomTagsService } from "./formio/custom-tags.service";
import { FormioAppConfig } from "./formio/formio.config";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AreaRoutes),
    FormsModule,
    UiModule
  ],
  declarations: [FormBuilderListComponent, FormBuilderAddOrEditComponent, FormBuilderComponent],
  providers: [FormService, CustomTagsService, FormioAppConfig]
})
export class FormBuilderModule {}
