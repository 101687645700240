import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { YardTurnaroundTimeViolationModel, YardTurnaroundTimeViolationUserModel } from '@ov-suite/models-yard';
import { UserRep } from '@ov-suite/models-idm';
import _ from 'lodash';
import { YardTurnaroundTimeViolationUsersService } from '../../../../services';

export interface ViolationDialogResponse {
  violation: YardTurnaroundTimeViolationModel;
  activeUsers: UserRep[];
  inactiveUsers: YardTurnaroundTimeViolationUserModel[];
}

@Component({
  selector: 'ov-suite-yard-turnaround-time-violation-dialog',
  templateUrl: './yard-turnaround-time-violation-dialog.component.html',
  styleUrls: ['./yard-turnaround-time-violation-dialog.component.css'],
})
export class YardTurnaroundTimeViolationDialogComponent implements OnInit {
  @Output()
  violationEventEmitter: EventEmitter<false | ViolationDialogResponse> = new EventEmitter<false | ViolationDialogResponse>();

  @Input() violationAlertTrigger: YardTurnaroundTimeViolationModel;

  ogViolationAlertTrigger: YardTurnaroundTimeViolationModel;

  @Input() systemUsers: UserRep[];

  // activatedViolationUsers: Set<UserRep> = new Set<UserRep>();
  activatedViolationUsers: Set<UserRep>;

  searchActiveViolationUsers: Set<UserRep>;

  ogActivatedViolationUsers: Set<UserRep>;

  inactivateViolationUsers: Set<UserRep>;

  searchInactiveViolationUsers: Set<UserRep>;

  ogInactiveViolationUsers: Set<UserRep>;

  registeredUsers: Map<number, YardTurnaroundTimeViolationUserModel>;

  searching = false;

  userSearch = '';

  @ViewChild('yardViolationForm') violationForm: NgForm;

  constructor(private readonly yardTurnaroundTimeViolationUsersService: YardTurnaroundTimeViolationUsersService) {}

  async ngOnInit(): Promise<void> {
    const users = [...this.systemUsers];

    // For the violation
    this.ogViolationAlertTrigger = _.clone(this.violationAlertTrigger);

    if (this.violationAlertTrigger.id) {
      const violationUsers = await this.yardTurnaroundTimeViolationUsersService.prepareActivatedUsers(this.violationAlertTrigger.id);

      this.registeredUsers = new Map(
        violationUsers.map(vUser => {
          return [vUser.userId, vUser];
        }),
      );
    }

    // For active users
    this.activatedViolationUsers = new Set(users.filter(user => this.registeredUsers?.get(user.id)));

    this.ogActivatedViolationUsers = new Set(this.activatedViolationUsers);

    this.searchActiveViolationUsers = new Set(this.activatedViolationUsers);

    // For inactive users
    this.inactivateViolationUsers = new Set(users.filter(user => !this.activatedViolationUsers.has(user)));

    this.ogInactiveViolationUsers = new Set(this.inactivateViolationUsers);

    this.searchInactiveViolationUsers = new Set(this.inactivateViolationUsers);
  }

  onCancelDialog() {
    this.violationEventEmitter.emit(false);
  }

  onSubmit() {
    if (this.violationForm.valid) {
      const dialogResult: ViolationDialogResponse = {
        violation: this.prepareViolationAlertResponse(),
        activeUsers: this.prepareUsersToActivate(),
        inactiveUsers: this.prepareUsersToDeactivate(),
      };

      this.violationEventEmitter.emit(dialogResult);
    }
  }

  prepareViolationAlertResponse(): YardTurnaroundTimeViolationModel {
    if (this.violationAlertTrigger !== this.ogViolationAlertTrigger) {
      return this.violationAlertTrigger;
    }

    return null;
  }

  prepareUsersToActivate(): UserRep[] {
    const usersToActivate = [];

    this.activatedViolationUsers.forEach(atu => {
      if (!this.ogActivatedViolationUsers.has(atu)) {
        usersToActivate.push(atu);
      }
    });

    return usersToActivate.length ? usersToActivate : null;
  }

  prepareUsersToDeactivate(): YardTurnaroundTimeViolationUserModel[] {
    const usersToDeactivate: YardTurnaroundTimeViolationUserModel[] = [];

    this.inactivateViolationUsers.forEach(ivu => {
      if (!this.ogInactiveViolationUsers.has(ivu)) {
        usersToDeactivate.push(this.registeredUsers.get(ivu.id));
      }
    });

    return usersToDeactivate.length ? usersToDeactivate : null;
  }

  activateUser(user: UserRep): void {
    this.inactivateViolationUsers.delete(user);

    this.activatedViolationUsers.add(user);

    this.setSearchList();
  }

  deactivateUser(user: UserRep): void {
    this.activatedViolationUsers.delete(user);

    this.inactivateViolationUsers.add(user);

    this.setSearchList();
  }

  /**
   * Captures user input on user search
   * @param searchInput
   */
  onSearchUser(searchInput: string): void {
    this.userSearch = searchInput.toLowerCase();

    this.setSearchList();
  }

  /**
   * Sets the visible list on user search
   */
  setSearchList(): void {
    // Remember that we have a global variable for the user search.
    this.searchActiveViolationUsers = new Set();

    this.searchInactiveViolationUsers = new Set();

    this.activatedViolationUsers.forEach(avu => {
      if (`${avu.firstName} ${avu.lastName}`.toLowerCase().includes(this.userSearch)) {
        this.searchActiveViolationUsers.add(avu);
      }
    });

    this.inactivateViolationUsers.forEach(ivu => {
      if (`${ivu.firstName} ${ivu.lastName}`.toLowerCase().includes(this.userSearch)) {
        this.searchInactiveViolationUsers.add(ivu);
      }
    });
  }
}
