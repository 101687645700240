import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { GeofenceNotificationConfig } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: GeofenceNotificationConfig,
      api: 'yardlink',
      createTitle: 'GeofenceNotificationConfig',
      route: ['geofence-notification-config'],
      tableTitle: 'GeofenceNotificationConfig',
      permissionId: 901,
    }),
  ],
})
export class GeofenceNotificationConfigModule {}

// ATOM
