import {
  OVEntity,
  OVField,
  OVSearchable,
  OVTable,
} from '@ov-suite/ov-metadata';

@OVEntity('ExceptionStatus', 'shared')
export class ExceptionCommonStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('ExceptionSeverity', 'shared')
export class ExceptionCommonSeverity {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Exception', 'shared')
@OVTable([
  {
    type: 'string',
    key: 'source',
    title: 'Source',
  },
  {
    type: 'string',
    key: 'sourceDetail',
    title: 'Source Detail',
  },
  {
    type: 'string',
    key: 'reporter',
    title: 'Reporter',
  },
  {
    type: 'string',
    key: 'detail',
    title: 'Detail',
  },
  {
    type: 'string',
    key: 'handler',
    title: 'Handler',
  },
  {
    type: 'string',
    key: 'assignee',
    title: 'Assignee',
  },
  {
    type: 'string',
    key: 'createdDate',
    title: 'Created Date',
  },
  {
    type: 'status',
    key: 'status',
    title: 'Status',
  },
  {
    type: 'status',
    key: 'severity',
    title: 'Severity',
  },
])
export class ExceptionCommon {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Source',
    placeholder: 'Source',
    required: false,
  })
  source: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Assignee',
    placeholder: 'Assignee',
    required: false,
  })
  assignee: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Detail',
    placeholder: 'Detail',
    required: false,
  })
  detail: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Source Detail',
    placeholder: 'Source Detail',
    required: false,
  })
  sourceDetail: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Handler',
    placeholder: 'Handler',
    required: false,
  })
  handler: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Reporter',
    placeholder: 'Reporter',
    required: false,
  })
  reporter: string;

  @OVField({
    type: 'string',
    title: 'Created Date',
    placeholder: 'CreatedDate',
    required: false,
  })
  createdDate: string;

  @OVField({
    type: () => ['string'],
    title: 'Users',
    tooltip:
      'Add multiple users',
    selectionType: 'multiple',
    flat: true,
    required: true,
  })
  involvedUsers: string[];

  @OVField({
    type: () => ExceptionCommonStatus,
    keys: ['id', 'name', 'color'],
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: ExceptionCommonStatus;

  @OVField({
    type: () => ExceptionCommonSeverity,
    keys: ['id', 'name', 'color'],
    dropdown: true,
    title: 'Severity',
    sidebar: true,
    generated: true,
  })
  severity: ExceptionCommonSeverity;
}
