import { Component, Input, OnInit } from '@angular/core';
import { DashboardWidget } from '../../../modules/statistics/dashboard-v2/charts.constants';

/**
 * Need for a subscription as we will need this to update when date ranges have changed individually
 * as well as globally from the charting options required.
 */

@Component({
  selector: 'ov-suite-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.css'],
})
export class WidgetsComponent implements OnInit {
  @Input() widget: DashboardWidget;

  ngOnInit(): void {}
}
